import React from 'react'
import { useEffect } from 'react';


export default function KidneyCancer() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
   <section style={{ backgroundColor: "#f5f5f5" }}>
    <h1
      style={{
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: "2rem",
        textShadow: "var(--text-shadow)",
        textTransform: "uppercase",
        color: "var(--black)",
        fontSize: "5rem",
        letterSpacing: ".4rem",
      }}
    >
 Kidney Cancer
      {/* <span style="    text-transform: uppercase;
      color: var(--green);"> Services</span> */}
    </h1>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src="image/kC.jpeg"
              className="img-fluid"
              style={{
                width: "100%",
                height: "500px",
                marginBottom: 20,
                marginTop: 20,
              }}
            />
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
         What is kidney cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15 }}>
            Kidney cancer is the abnormal growth of cells in your kidney tissue. In time, these cells form a mass called a tumor. Cancer begins when something triggers a change in the cells, and they divide out of control.

A cancerous or malignant tumor can spread to other tissues and vital organs. When this happens, it’s called metastasis.


            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
          Who does kidney cancer affect?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Kidney cancer is most common in people between the ages of 65 and 74. Men are twice as likely as women to develop the disease. It’s also more common in Native American and Black populations.

Kidney cancer is much less common in children. However, 500 to 600 children are diagnosed with a Wilms tumor (a type of kidney cancer) every year in the United States.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
           What are the types of kidney cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            There are different types of kidney cancer, including:<br></br>
           <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Renal cell carcinoma (RCC):</span>  This is the most common form of kidney cancer in adults and accounts for 85% of all kidney cancers. Renal cell carcinoma usually develops as a single tumor in one kidney, but it can affect both kidneys. The cancer begins in the cells that line your kidney’s tubules (tiny tubes that return nutrients and fluid back to your blood). The most common type of RCC is clear cell renal cell carcinoma (ccRCC).
            <br></br><span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Transitional cell cancer:</span>Transitional cell carcinoma accounts for 6% to 7% of all kidney cancers. This cancer usually begins in the area where your ureter connects to the main part of your kidney. This area is called your renal pelvis. Transitional cell carcinoma can also occur in your ureters or bladder.
            <br></br><span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Renal sarcoma: </span> This is the least common form of kidney cancer, accounting for only 1% of kidney cancer cases. It begins in the connective tissues of your kidneys and, if not treated, can spread to nearby organs and bones.
            <br></br><span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Wilms tumor:</span> This is the most common type of kidney cancer in children. It accounts for about 5% of kidney cancers.
            </p>

          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >What are the signs of kidney cancer?
            </h3>
            <img
              src="image/KC2.jpeg"
              className="img-fluid"
              style={{
                marginBottom: 20,
                width: "100%",
                height: "300px",
                marginTop: 20,
              }}
            />
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Kidney cancer may not produce any noticeable symptoms in its early stages. But as the tumor grows, symptoms may begin to appear. For that reason, kidney cancer often isn’t diagnosed until it has begun to spread.<br>
            </br>
            Kidney cancer symptoms may include:
            <br></br>
           - Blood in your pee (hematuria).<br></br>
- A lump or mass in your kidney area.<br></br>
- Flank pain.<br></br>
- Tiredness.<br></br>
- A general sense of not feeling well.<br></br>
- Loss of appetite.<br></br>
- Weight loss.<br></br>
- Low-grade fever.<br></br>
- Bone pain.<br></br>
- High blood pressure.<br></br>
- Anemia.<br></br>
- High calcium.
            </p>
            {/* <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Ovulation induction is usually used in the sense of stimulation of
              the development of ovarian follicles by fertility medication to
              reverse anovulation or oligoovulation. These medications are given
              by injection for 8 to 14 days. A health care provider closely
              monitors the development of the eggs using transvaginal ultrasound
              and blood tests to assess follicle growth and estrogen production
              by the ovaries. When follicles have reached an adequate size and
              the eggs are mature enough, an injection of the hormone hCG
              initiates the ovulation process. Egg retrieval should occur from
              34 to 36 hours after the hCG injection
            </p> */}
          </div>

          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >What are the kidney cancer stages?
            </h3>
           
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Most cancers are grouped by stage, a description of cancer that aids in planning treatment. The stage of a cancer is based on:<br/>

            The location and size of the tumor.<br/>
The extent to which your lymph nodes are affected.<br/>
The degree to which the cancer spread, if at all, to other tissue and organs.<br/>
Your healthcare provider uses information from various tests, including CT, MRI and biopsy, to determine the stage of cancer.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage I:</span>The tumor is 7 centimeters (cm) across or smaller and is only in your kidney. It hasn’t spread to lymph nodes or other tissue. (Lymph nodes are small “filters” that trap germs and cancer cells and store infection-fighting cells.).<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage II: </span>The tumor is larger than 7 cm across but is still only in your kidney. It hasn’t spread to lymph nodes or other tissue.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage III:</span>The tumor has spread to your major blood vessels — your renal vein and inferior vena cava — or into the tissue surrounding your kidney or to nearby lymph nodes.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage IV:</span>The tumor has spread outside of your kidney to your adrenal gland (the small gland that sits on top of your kidney), or to distant lymph nodes or other organs.<br/>
Tumors are also graded, which is a way of rating a tumor based on how abnormal its cells look. Tumor grading can also tell your healthcare provider how fast the tumor is likely to grow. Tumors whose cells don’t look like normal cells and divide rapidly are called high-grade tumors. High-grade tumors tend to grow and spread more quickly than low-grade tumors.
            </p>

            <img src='/image/KC3.jpeg' style={{width:"100%"}}></img>
            
          </div>
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Estimated Time Required:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Most couples are able to get pregnant within six months to a year.
              If you're unable to conceive after a full year of trying, it's a
              good idea to consult a fertility specialist. Sometimes there's an
              obvious cause of infertility, like a physical problem with the
              ovaries, uterus, or testicles.
            </p>
          </div> */}
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Number Of Visits And Hospitalization:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              If you have been trying to conceive for more than a year (or six
              months if you are over 35 years old), it is recommend that you
              undergo an evaluation. A fertility specialist can help determine
              why you are having difficulty and offer possible treatment options
              to help you conceive.
            </p>
          </div> */}
          {/* <div>
                  <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
              </div> */}
          <a
            href="services.html"
            target="_blank"
            className="btn"
            style={{ marginBottom: 20 }}
          >
            {" "}
            Kidney Cancer{" "}
            <span className="fas fa-chevron-right" />{" "}
          </a>
          <h3
            style={{
              color: "brown",
              fontWeight: "bolder",
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            For further queries related to the treatment, Please call
            +91-9587025972 or write to naveen.vermab4@gmail.com
          </h3>
        </div>
      </div>
    </div>
  </section>
  {/* booking section ends */}
  {/* Remove the container if you want to extend the Footer to full width. */}
  <div className="container my-5"></div>


   </>
  )
}
