import React from 'react'

const Ivf = () => {
  return (
   <>
     <section style={{ backgroundColor: "#f5f5f5" }}>
    <h1
      style={{
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: "2rem",
        textShadow: "var(--text-shadow)",
        textTransform: "uppercase",
        color: "var(--black)",
        fontSize: "5rem",
        letterSpacing: ".4rem",
      }}
    >
      In-Vitro-Fertilization
      {/* <span style="    text-transform: uppercase;
  color: var(--green);"> Services</span> */}
    </h1>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-9">
          <div>
            <img
              src="image/ivf.jpg"
              className="img-fluid"
              style={{
                width: "84rem",
                height: "30rem",
                marginBottom: 20,
                marginTop: 20,
              }}
            />
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              In Vitro Fertilization (IVF)
            </h3>
            <p style={{ color: "black", fontSize: 15 }}>
              IVF or In Vitro Fertilization, commonly known, as ‘test tube baby‘
              is the miracleterm for infertile couples
              <br /> desirous of a child. This technology has enabled millions
              of hopeless couples to have children of their
              <br /> own, and experience the unmatched joy of parenthood.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Who Needs IVF?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Tubal Damage or Block
              <br />
              Absent or Low Sperm Count (&lt; 5million /ml)
              <br />
              Damaged uterine lining (Endometrium)
              <br />
              Poor Ovarian (Egg) capacity/Advanced female age
              <br />
              Failure of Fertility treatment for more than 2 to 5 years.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Success Rate Of IVF:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              In best cases (good sperm, egg and uterine quality) the success
              rate may reach up to 50-60% <br />
              attempt and 90% in 2-3 attempts. However, where quality is not
              good success rates are lower.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Process Of IVF :
            </h3>
            <img
              src="image/IVF-cycle.png"
              className="img-fluid"
              style={{
                marginBottom: 20,
                width: "84rem",
                height: "30rem",
                marginTop: 20,
              }}
            />
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              The IVF process consists of{" "}
              <span style={{ color: "brown", fontWeight: "bold" }}>
                ovarian stimulation, egg collection, fertilization, embryo
                culture and embryo transfer.
              </span>{" "}
              Screening processes like normal ultrasound and blood tests are a
              pre-requisite for <br />
              proceeding with IVF. If any abnormality like fibroid etc. is
              detected, it is treated before proceeding
              <br /> with IVF.
            </p>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Ovarian stimulation requires daily injection of hormones (usually
              for 10-12 days) to the female
              <br /> partner with frequent clinical and ultrasonographic
              monitoring.
              <br />
              Egg retrieval is performed under anesthesia, internally without
              any incisions or scars.
              <br />
              The process involves either mixing the egg with the sperm (IVF) or
              injecting the sperm into the egg, known as Intracytoplasmic Sperm
              Injection (ICSI).
              <br />
              The egg once fertilized by this process forms the embryo, which is
              then cultured in the laboratory for 2 – 3 days.
              <br />
              The best 2 – 3 embryos are then selected and transferred into the
              womb (uterus) on the second or the third day.
              <br />
              The Blastocyst is transferred on the fifth day, decided from
              case-to-case basis.
              <br />
              The pregnancy test is carried out fourteen days after embryo
              transfer.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Estimated Time Required And When To Arrive:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              One IVF cycle takes about l5 days from start of period to embryo
              transfer. In this case, you need
              <br /> to reach 3-4 days before the period starts.
              <br />
              Many patients may not be able to spare so much time. If you
              collect your medications from us in
              <br /> advance your stay can be reduced to a week to 10 days
              around the time of embryo transfer. In <br />
              this case, you can reach on the 7th or 8th day of your period.
              <br />
              You can travel back 2-3 days after embryo transfer. We are sure
              that travelling does not reduce <br />
              the chance of pregnancy.
              <br />
              It takes 15 days from the embryo transfer to obtain the pregnancy
              test result.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Number Of Visits And Hospitalization:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              The total number of visits may vary from 4-6 usually.
              <br />
              There is no overnight hospitalization and both egg pick up
              andembryo transfer are day care <br />
              procedures.
              <br />
              Husband needs to come on day of egg pick up or freeze sperms
              beforehand.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              COST OF IVF
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac,
              depending on the case. On an average, it is about Rs 1.5 lacs. The
              package includes the hormonal injection used for stimulation, the
              oral medication and vitamins for husband and wife during IVF
              stimulation, doctors consultation fees and follicular monitoring
              during the IVF cycle.
            </p>
          </div>
          <a
            href="services.html"
            target="_blank"
            className="btn"
            style={{ marginBottom: 20 }}
          >
            {" "}
            Test Tube Baby/ IVF Calculator{" "}
            <span className="fas fa-chevron-right" />{" "}
          </a>
          <h3
            style={{
              color: "brown",
              fontWeight: "bolder",
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            For further queries related to the treatment, Please call
            +91-9587025972 or write to contact@advancefertility.in
          </h3>
        </div>
      </div>
    </div>
  </section>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
            textAlign: "center",
            marginTop: 19,
          }}
        >
          Our Related Services
        </h3>
        <div className="swiper food-slider">
          <div className="swiper-wrapper" style={{ marginBottom: 30 }}>
            <div
              className="swiper-slide slide"
              data-name="food-1"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/A Treatment That Is Helping Couples Become Parents.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                A Treatment That Is Helping Couples Become Parents
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-2"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/FERC.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Frozen Embryo Replacement Cycle (FERC)
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-3"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/Surgical Sperm Retrieval.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Surgical Sperm Retrieval
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-4"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ivf pre.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                IVF Pregnancy
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/faild ivf.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Failed IVF Cycle
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ICSI.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Intracytoplasmic Sperm Injection (ICSI)
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ivf-c.png"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                In Vitro Fertilization (IVF)
              </h3>
            </div>
            <div id="holder">
              <div
                className="swiper-slide slide"
                data-name="food-5"
                style={{
                  marginLeft: 20,
                  height: "32rem",
                  width: "413.667px",
                  background: "var(--white)",
                  boxShadow: "var(--box-shadow)",
                  border: "var(--border)",
                  marginRight: 20,
                }}
              >
                <img
                  src="image/iui.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
                <h3
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  Intrauterine Insemination (IUI)
                </h3>
              </div>
            </div>
          </div>
          <div className="swiper-pagination " />
        </div>
      </div>
    </div>
  </div>
  <section className="about" id="about">
    <h1 className="heading">
      {" "}
      <span>About</span> Dr. Chandra
    </h1>
    <div className="row">
      <div className="image">
        <img src="image/namitama'am.png" alt />
      </div>
      <div className="content">
        <h1>
          Infertility &amp; IVF Specialist, Clinical Director At Advance
          Fertility &amp; Gynecological Centre
        </h1>
        <p>
          A seasoned Obstetrician &amp; Gynecologist with more than a decade of
          experience in IVF infertility management, Dr. Namita Chandra Verma is
          renowned infertility and Best IVF Doctor in India. She is an expert in
          her field, trained from the prestigious Guys and St. Thomas Hospital,
          London, where she went as a Commonwealth scholar and has spent three
          years in London (UK) doing rigorous training in the domain of
          infertility and IVF. She completed her MBBS and MD in Obstetrics &amp;
          Gynecology from the prestigious All India Institute of Medical
          Sciences (AIIMS), New Delhi.
        </p>
        <p>
          She has worked as a Senior IVF Specialist in major corporate hospitals
          in Delhi and is the organizing chairperson of CUPART (Current
          Practices and Recent Advances in ART), an International organization
          aimed to facilitate the right treatment and research in fertility
          &amp; IVF.
        </p>
        <a  className="btn">
          {" "}
          learn more <span className="fas fa-chevron-right" />{" "}
        </a>
      </div>
    </div>
  </section>
   </>
  )
}

export default Ivf

