import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate, useLocation } from 'react-router-dom';

function NavBarO() {
  const navigate = useNavigate();
  return (
    // <Navbar style={{fontSize:'1.5rem', position:"sticky", top:0, height: '70px', maxHeight: '100%', }} collapseOnSelect expand="lg" bg="dark" variant="dark">
    //   <Container>
    //     <Navbar.Brand onClick={() => navigate('/')} ><a className="logo" style={{color:"white"}}> <i className="fas fa-heartbeat" style={{color:"white"}}></i> Expert Surgical Solutions+</a></Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="me-auto">
    //         <Nav.Link onClick={() => navigate('/')} style={{ cursor: 'pointer', marginLeft:"20px" }}>Home</Nav.Link>
    //         <NavDropdown style ={{marginLeft:"20px"}} title="Services" id="collasible-nav-dropdown">
    //           <NavDropdown.Item onClick={()=>navigate('/Itfemails')}>Endometrial Cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/orell')}>Oral Cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/stomach')}>Stomach cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/colorectal')}>Colorectal cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/pancreatic')}>Pancreatic Cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/esophageal')}>Esophageal Cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/bladder')}>Bladder Cancer</NavDropdown.Item>
    //           <NavDropdown.Item onClick={()=>navigate('/bones')}>Bone Cancer</NavDropdown.Item>

    //         </NavDropdown>
    //         <Nav.Link onClick={() => navigate('/about')} style={{ cursor: 'pointer', marginLeft:"20px" }}>About</Nav.Link>
    //         <Nav.Link  onClick={() => navigate('/doctors')} style={{ cursor: 'pointer',marginLeft:"20px" }}>Doctors</Nav.Link>
    //         <Nav.Link  onClick={() => navigate('/appoinment')} style={{ cursor: 'pointer',marginLeft:"20px" }}>Appointment</Nav.Link>
    //         {/* <Nav.Link onClick={() => navigate('/gallery')} style={{ cursor: 'pointer', marginLeft:"20px" }}>Gallery</Nav.Link> */}
    //       </Nav>
    //       <Nav>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>

//     <Navbar style={{ fontSize:'1.5rem',position: 'sticky', top: 0,height: '70px', maxHeight: '100%' }} collapseOnSelect expand="lg" bg="dark" variant="dark">
//   <Container>
//     <Navbar.Brand onClick={() => navigate('/')}>
//       <a className="logo" style={{ color: "white" }}>
//         <i className="fas fa-heartbeat" style={{ color: "white" }}></i> Expert Surgical Solutions+
//       </a>
//     </Navbar.Brand>
//     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//     <Navbar.Collapse id="responsive-navbar-nav">
//       <Nav className="me-auto">
//         <Nav.Link onClick={() => navigate('/')} style={{ cursor: 'pointer', marginLeft: "20px" }}>Home</Nav.Link>
//         <NavDropdown style={{ marginLeft: "20px" }} title="Services" id="collasible-nav-dropdown">
//           <NavDropdown.Item onClick={() => navigate('/Itfemails')}>Endometrial Cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/orell')}>Oral Cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/stomach')}>Stomach cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/colorectal')}>Colorectal cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/pancreatic')}>Pancreatic Cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/esophageal')}>Esophageal Cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/bladder')}>Bladder Cancer</NavDropdown.Item>
//           <NavDropdown.Item onClick={() => navigate('/bones')}>Bone Cancer</NavDropdown.Item>
//         </NavDropdown>
//       </Nav>
//       <Nav>
//         <Nav.Link onClick={() => navigate('/about')} style={{ cursor: 'pointer', marginRight: "20px" }}>About</Nav.Link>
//         <Nav.Link onClick={() => navigate('/doctors')} style={{ cursor: 'pointer', marginRight: "20px" }}>Doctors</Nav.Link>
//         <Nav.Link onClick={() => navigate('/appoinment')} style={{ cursor: 'pointer', marginRight: "20px" }}>Appointment</Nav.Link>
//       </Nav>
//     </Navbar.Collapse>
//   </Container>
// </Navbar>



<Navbar style={{ backgroundColor:'#ADD8E6' ,position: 'sticky', top: 0, fontSize:'2.5rem' }} collapseOnSelect expand="lg" >
  <Container>
    <Navbar.Brand onClick={() => navigate('/')}>
      <a className="logo" style={{fontSize:'2rem', color: "#000000" }}>
        <i className="fas fa-heartbeat" style={{ color: "#000000", }}></i> Expert Surgical Solutions+
      </a>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link onClick={() => navigate('/')} style={{ cursor: 'pointer', marginLeft: "20px" }}>Home</Nav.Link>
        <NavDropdown style={{ marginLeft: "20px" }} title="Services" id="collasible-nav-dropdown">
          <NavDropdown.Item onClick={() => navigate('/Itfemails')}>Endometrial Cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/orell')}>Oral Cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/stomach')}>Stomach cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/colorectal')}>Colorectal cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/pancreatic')}>Pancreatic Cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/esophageal')}>Esophageal Cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/bladder')}>Bladder Cancer</NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate('/bones')}>Bone Cancer</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav className="justify-content-end" style={{ width: "100%" }}>
        <Nav.Link onClick={() => navigate('/about')} style={{ cursor: 'pointer', marginLeft: "20px", marginRight: "20px" }}>About</Nav.Link>
        <Nav.Link onClick={() => navigate('/doctors')} style={{ cursor: 'pointer', marginLeft: "20px", marginRight: "20px" }}>Doctors</Nav.Link>
        <Nav.Link onClick={() => navigate('/appoinment')} style={{ cursor: 'pointer', marginLeft: "20px", marginRight: "20px" }}>Appointment</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>


  );
}

export default NavBarO;