import React from 'react'
import { useEffect } from 'react'


export default function LiverCancer() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
      <>
   <section style={{ backgroundColor: "#f5f5f5" }}>
    <h1
      style={{
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: "2rem",
        textShadow: "var(--text-shadow)",
        textTransform: "uppercase",
        color: "var(--black)",
        fontSize: "5rem",
        letterSpacing: ".4rem",
      }}
    >
Liver Cancer
      {/* <span style="    text-transform: uppercase;
      color: var(--green);"> Services</span> */}
    </h1>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src="image/LC.jpeg"
              className="img-fluid"
              style={{
                width: "100%",
                height: "500px",
                marginBottom: 20,
                marginTop: 20,
              }}
            />
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
What is liver cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15 }}>
            Liver cancer is a life-threatening illness and one of the fastest-growing cancer types in the United States. There are two kinds of liver cancer: primary and secondary. Primary cancer starts in your liver. Secondary cancer spreads to your liver from another part of your body. This article is an overview of primary liver cancer.
            <br/>
            Like many kinds of cancer, healthcare providers can do more to treat liver cancer during the disease’s early stage. Unlike many kinds of cancer, healthcare providers have a good idea of what increases someone’s risk of developing liver cancer. With that in mind, healthcare providers are intent on identifying who may be at increased risk so they can catch and treat primary liver cancer as early as possible.

            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
What is the main cause of liver cancer?         </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Liver cancer happens when something affects healthy liver cells’ DNA. DNA carries the genes that tell our cells how to function. We all have genes that tell cells when to grow, multiply and die. For example, oncogenes help cells grow and divide. Other genes, called tumor suppressor genes, monitor cell activity, keeping cells from multiplying uncontrollably and making sure cells die when they’re supposed to die.
<br/>
When our DNA mutates or changes, our cells get new instructions. In HCC, DNA changes turn on oncogenes and/or turn off tumor suppressor genes. For example, studies show cirrhosis related to hepatitis B virus (HBV) and hepatitis C virus (HCV) account for more than half of all HCC cases. When these viruses infect liver cells, they change cell DNA, turning healthy liver cells into cancerous cells.
<br/><span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>What causes IHC?</span>
<br/>Healthcare providers believe long-term inflammation in your bile ducts increases the risk for developing IHC. This long-term inflammation may cause DNA changes that turn healthy cells into abnormal cells.<br/>


            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
   What are liver cancer symptoms?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            When liver cancer is in its early stages, you might have no symptoms at all. Hepatocellular carcinoma (HCC) and intrahepatic cholangiocarcinoma (IHC) have similar symptoms:
            <br/>- A lump below your rib cage or pain on the right side of your abdomen, or pain near your right shoulder.
<br/>- Jaundice (a disease that causes skin and eyes to yellow).
<br/>- Unexplained weight loss, nausea, or loss of appetite.
<br/>- Fatigue.
<br/>- Dark-colored urine.
            </p>

          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >What are liver cancer stages?
            </h3>
            <img
              src="image/LC2.jpeg"
              className="img-fluid"
              style={{
                marginBottom: 20,
                width: "100%",
                height: "300px",
                marginTop: 20,
              }}
            />
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Healthcare providers stage HCC using standards set by the Barcelona Clinic Liver Cancer system (BCLC). This system evaluates HCC liver on characteristics, including whether your liver is working well, tumor size and your symptoms. Healthcare providers may use different terms for each BCLC stage; sometimes, presented as being stages I to IV or 0-C or by terms such as early and advanced stage HCC.<br/>
            Hepatocellular carcinoma stages include the following:
            <br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage I/very early stage/stage 0:</span>
            <br></br>
            You have a single tumor in your liver that measures less than 2 centimeters (cm). Blood tests show your bilirubin level is normal.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage II/early stage/stage A:</span><br/>
            You have a single tumor that measures 5 cm or less or you have more than one tumor that measures less than 3 cm. The tumor may have spread to your blood vessels.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage III/intermediate stage/stage B: </span><br/>
            In this stage, you may have more than one tumor and/or a tumor that measures more than 5 cm. The tumor may have spread to your lymph nodes, large blood vessels or another organ.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage IV/advanced stage/stage C:</span><br/>
            The cancer has spread to other places in your body, such as your lungs or bones, as well as lymph nodes.
            <br/>
            






            
       
            </p>
           
          </div>

          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >How can I prevent liver cancer?
            </h3>
           
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            While you can’t completely prevent liver cancer, you can do the following to lower your chances of getting liver cancer:
<br/>
- Avoid behaviors that lead to cirrhosis.
<br/>- Reach or maintain a healthy weight.
<br/>- Get a hepatitis B vaccine. This vaccine is safe for nearly everyone. Ask your doctor about the hepatitis A vaccine.
<br/>- Avoid hepatitis C.
<br/>- If you have any liver disease, have diabetes, obesity or are a heavy drinker, ask your healthcare provider about liver cancer screenings.

            </p>
            
          </div>
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Estimated Time Required:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Most couples are able to get pregnant within six months to a year.
              If you're unable to conceive after a full year of trying, it's a
              good idea to consult a fertility specialist. Sometimes there's an
              obvious cause of infertility, like a physical problem with the
              ovaries, uterus, or testicles.
            </p>
          </div> */}
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Number Of Visits And Hospitalization:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              If you have been trying to conceive for more than a year (or six
              months if you are over 35 years old), it is recommend that you
              undergo an evaluation. A fertility specialist can help determine
              why you are having difficulty and offer possible treatment options
              to help you conceive.
            </p>
          </div> */}
          {/* <div>
                  <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
              </div> */}
          <a
            href="services.html"
            target="_blank"
            className="btn"
            style={{ marginBottom: 20 }}
          >
            {" "}
            Liver Cancer{" "}
            <span className="fas fa-chevron-right" />{" "}
          </a>
          <h3
            style={{
              color: "brown",
              fontWeight: "bolder",
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            For further queries related to the treatment, Please call
            +91-9871250235 or write to naveen.vermab4@gmail.com
          </h3>
        </div>
      </div>
    </div>
  </section>
  {/* booking section ends */}
  {/* Remove the container if you want to extend the Footer to full width. */}
  <div className="container my-5"></div>


   </>
  )
}
