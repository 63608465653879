import React from 'react'

const Doctors = () => {
  return (
   <>
   <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div style={{ marginTop: '20%', marginLeft: '18%' }}>
                    <h2 style={{ fontSize: '3rem', color: '#0088a5', fontFamily: 'Poppins, sans-serif !important' }} class="mb-3">Doctor</h2>
                    <h1  style={{color: '#0088a5', fontSize: '2.7rem', lineHeight: '110%', fontWeight: 500}}> Dr. Naveen Verma </h1>
                    <p  style={{ fontSize: '21px' }}> Cancer Surgeon </p>
                    <br />
                    <div class="d-flex">
                         <i class="fas fa-stethoscope icon text dvc-pink" style={{ color: '#ec268f', fontSize: '25px' }}></i> 
                         <div class="content ms-3" style={{ fontSize: '22px' }}> MBBS, MS-General Surgery </div> 
                        </div>

                        <div class="d-flex"> 
                            <i class="fas fa-stethoscope icon text dvc-pink" style={{ color: '#ec268f', fontSize: '25px' }}></i> 
                            <div class="content ms-3" style={{ fontSize: '22px' }}> MCH Surgical Oncology  </div>
                         </div>          
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <img src="image/drnav2.jpeg" class="img-fluid first-img"/>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6" style={{ backgroundColor: '#eceff1' }}>
                <div>
                    <h2 class="" style={{ marginLeft: '4rem', color: '#ec268f', marginTop: '80px', fontSize: '2rem' }}
> Educational qualification </h2>

                    <table class="table table-bordered" style={{marginTop: '2rem'}}>
                        
                        <tbody>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> Speciality </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <p class="mt-3 ms-2" style={{fontSize: "18px"}}> MBBS &amp; MS in General Surgery &amp; MCH Surgical Oncology </p>

                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> MCH Surgical Oncology
                                      </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> 2022 </div>
                                <p class="mt-3 ms-2" style={{fontSize: "18px", color: "#ec268f"}}>  BJMC Gujarat Cancer Research Institute(GCRI), Ahmedabad, 
Gujarat </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}>MS-General Surgery </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> 2017 </div>
                                <p class="mt-3 ms-2" style={{fontSize: "18px", color: "#ec268f"}}>   Sawai Man Singh Medical college Jaipur, 
Rajasthan  </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> MBBS </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> 2013 </div>
                                <p class="mt-3 ms-2" style={{fontSize: "18px", color: "#ec268f"}}>    Chhattisgarh Institute Of Medical Sciences, Bilaspur, Chhattisgarh   </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}>  Registration  </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> CGMC 4839/2013 </div>
                                <p class="mt-3 ms-2" style={{fontSize: "18px", color: "#ec268f"}}>    Chhattisgarh Medical Council                                </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: '1em', backgroundColor: '#ec268f', color: 'aliceblue', width: 'rem'}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}>  Membership  </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}>  The Association of Surgeons Of India </div>
                                {/* <!-- <p class="mt-3 ms-2" style={{fontSize: "18px"}}>    Maharashtra Medical Council</p> --> */}
                             </td>
                            
                          </tr>

                          
                          
                        </tbody>
                      </table>
                </div>
            </div>


            <div class="col-md-6" >
                <div>
                    <h2 style={{marginLeft: '4rem', color: '#0088a5', marginTop: '80px', fontSize: '2rem'}}>Experience </h2>

                    <table class="table table-bordered" style={{ marginTop: '2rem' }}>
                        
                        <tbody>
                          {/* <tr>
                            <td class="" style={{ padding: "1em !important", backgroundColor: "#0088a5", color: "aliceblue", width: "rem" }}
>
                                 <div class="" style={{ fontSize: '18px', marginLeft: '15px', marginTop: '25px', marginBottom: '19px' }}> Specialist Gynecologist</div> 
                                </td>
                            <td style={{ padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)" }}
> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> Jan 2022 To Sep 2022 </div>
                                <p class="mt-3 ms-2" style={{ fontSize: '18px', color: '#0088a5' }}> Dr. Nagori’s Institute For Infertility And IVF</p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: "1em !important", backgroundColor: "#0088a5", color: "aliceblue", width: "rem"}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> Specialist Gynecologist </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> Apr 2021 To Dec 2021 </div>
                                <p class="mt-3 ms-2" style={{ fontSize: '18px', color: '#0088a5' }}>  CHC Dabhara (Dist. Sakti) </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: "1em !important", backgroundColor: "#0088a5", color: "aliceblue", width: "rem"}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> Asisstant Gynecologist </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                            <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> Sep 2019 To Mar 2020 </div>
                                <p class="mt-3 ms-2" style={{ fontSize: '18px', color: '#0088a5' }}>  Sunflower Women’s Hospital, Ahmedabad
                                </p>
                             </td>
                            
                          </tr> */}
                          <tr>
                            <td class="" style={{padding: "1em !important", backgroundColor: "#0088a5", color: "aliceblue", width: "rem"}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}> Scholarship </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}> 	2017 </div>
                                <p class="mt-3 ms-2" style={{ fontSize: '18px', color: '#0088a5' }}>     Dr. G.C.Sharma International Travelling Fellowship Grant  </p>
                             </td>
                            
                          </tr>
                          <tr>
                            <td class="" style={{padding: "1em !important", backgroundColor: "#0088a5", color: "aliceblue", width: "rem"}}>
                                 <div class="" style={{fontSize: "18px", marginLeft: "15px", marginTop: "25px", marginBottom: "19px"}}>  Assistant Professor </div> 
                                </td>
                            <td style={{padding: "1em !important", borderLeft: "0.06em solid rgba(255,255,255,0.7)"}}> 
                                <div class=" mt-3 ms-2" style={{fontSize: '18px'}}>  2017 – 2018. </div>
                                <p class="mt-3 ms-2" style={{ fontSize: '18px', color: '#0088a5' }}>  Department Of General Surgery, Chandulal Chandrakar Memorial 
Hospital & Medical College, Durg Chhattisgarh </p>
                             </td>
                            
                          </tr>
                         

                          
                          
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
    <div class="row">
    <div className="col-md-6">
  <div style={{ marginTop: "35%", marginLeft: "18%" }}>
    <h2 style={{ fontSize: "39px", color: "#ec268f", fontFamily: "Poppins,sans-serif!important" }} className="mb-3">OPEN HOURS</h2>
    <h1 className="" style={{ color: "#0088a5", fontSize: "1.7rem", lineHeight: "110%", fontWeight: 500 }}> Dr. Naveen's Surgical Clinic </h1>

    <br />
    <div className="d-flex">

      <div className="content mb-4" style={{ fontSize: "18px" }}> These are clinic timings for Dr. Naveen's Surgical Clinic.
          Dr. Naveen is also available at other locations. To know about other location timings, refer to the book an appointment page. </div>
    </div>

    {/* <div style={{ maxWidth: "265px", marginTop: "22px" }}>
      <a title="Book appointment with Dr. Varshali Mali (MBBS, DNB). Gynecologist &amp; Pregnancy Doctor" className="book-an-appointment-link" style={{ color: "#4183c4", textDecoration: "none", backgroundColor: "transparent" }}>
        <div className="svg-button svg-dvc-pink-button" style={{ height: "42px", cursor: "pointer" }}>
          <svg height="42" width="265" xmlns="http://www.w3.org/2000/svg">
            <rect height="100%" width="100%" className="svg-button-background" style={{ stroke: "#ec268f", fill: "#ec268f" }}></rect>
            <rect height="100%" width="17%" x="83%" className="svg-button-icon-background" style={{ fill: "#000", fillOpacity: ".05" }}></rect>
            <g>
              <text className="svg-button-text" x="20" y="65%">Book an appointment</text>
            </g>
            <g>
              <text className="svg-button-icon" style={{ fill: "#fff" }} x="89%" y="65%"></text>
            </g>
          </svg>
        </div>
      </a>
    </div> */}
  </div>
</div>

<div class="col-md-6" style={{ backgroundColor: '#424242' }}>
<table class="" style={{ color: '#FFFFFF', marginBottom: '5rem', marginTop: '10rem' }}> 
<thead>
  <tr>
    <th style={{background: 'none'}} className="no-border"></th>
    <th className="center aligned no-border" style={{background: 'none', paddingBottom: '1.6em'}}>
      <img src="image/morning.svg" className="img-fluid" style={{width: '5.4em', marginLeft: 'px'}} />
      <div className="" style={{fontSize: '18px', marginLeft: 'px'}}><p>Morning</p></div>
    </th>
    <th className="center aligned no-border" style={{background: 'none', paddingBottom: '1.6em'}}>
      <img src="image/evening.svg" className="img-fluid" style={{width: '5.4em', marginLeft: 'px'}} />
      <div className="" style={{fontSize: '18px', marginLeft: 'px'}}><p>Evening</p></div>
    </th>
  </tr>
</thead>
<tbody>
<tr className="no-padding">
      <td style={{padding: "1em !important", width: "19rem", textAlign: "center", fontSize: "15px"}} className="no-border">
        <div className="font-weight-bold">Monday</div>
      </td>
      <td className="opacity-100 center aligned" style={{backgroundColor: "#ec268f", width: "20rem", height: "10rem", borderWidth: "0.06em 0 0 0.06em", borderStyle: "solid", borderColor: "rgba(255,255,255,0.7)", borderTopLeftRadius: ".28571429rem"}}>
        <div style={{textAlign: "center", fontSize: "20px"}}>9:00 AM to 12:30 PM</div>
      </td>
      <td className="opacity-100 center aligned" style={{backgroundColor: "#ec268f", width: "20rem", padding: "1em !important", borderWidth: "0.06em 0.06em 0 0.06em", borderStyle: "solid", borderColor: "rgba(255,255,255,0.7)", borderTopRightRadius: ".28571429rem"}}>
        <div style={{textAlign: "center", fontSize: "20px"}}>2:00 PM to 5:00 PM</div>
      </td>
    </tr>
    <tr className="no-padding">
  <td style={{padding: "1em !important", width: "19rem", textAlign: "center", fontSize: "15px"}} className="no-border">
    <div className="font-weight-bold">Tuesday</div>
  </td>
  <td className="opacity-100 center aligned" style={{backgroundColor: "#ec268f", height: "10rem", borderWidth: "0.06em 0 0 0.06em", borderStyle: "solid", borderColor: "rgba(255,255,255,0.7)"}}>
    <div className="" style={{textAlign: "center", fontSize: "20px"}}>9:00 AM to 12:30 PM</div>
  </td>
  <td className="opacity-100 center aligned" style={{backgroundColor: "#ec268f", padding: "1em !important", borderWidth: "0.06em 0.06em 0 0.06em", borderStyle: "solid", borderColor: "rgba(255,255,255,0.7)"}}>
    <div className="" style={{textAlign: "center", fontSize: "20px"}}>2:00 PM To 5:00 PM</div>
  </td>
</tr>
<tr className="no-padding">
  <td
    style={{
      padding: "1em !important",
      width: "19rem",
      textAlign: "center",
      fontSize: "15px"
    }}
    className="no-border"
  >
    <div className="font-weight-bold">Wednesday</div>
  </td>
  <td
    className="opacity-100 center aligned"
    style={{
      backgroundColor: "#ec268f",
      height: "10rem",
      borderWidth: "0.06em 0 0 0.06em",
      borderStyle: "solid",
      borderColor: "rgba(255,255,255,0.7)"
    }}
  >
    <div style={{ textAlign: "center", fontSize: "20px" }}>
    9:00 AM to 12:30 PM
    </div>
  </td>
  <td
    className="opacity-100 center aligned"
    style={{
      backgroundColor: "#ec268f",
      padding: "1em !important",
      borderWidth: "0.06em 0.06em 0 0.06em",
      borderStyle: "solid",
      borderColor: "rgba(255,255,255,0.7)"
    }}
  >
    <div style={{ textAlign: "center", fontSize: "20px" }}>
    2:00 PM To 5:00 PM
    </div>
  </td>
</tr>
<tr className="no-padding">
          <td
            style={{
              padding: "1em !important",
              width: "19rem",
              textAlign: "center",
              fontSize: "15px",
            }}
            className="no-border"
          >
            <div className="font-weight-bold">Monday</div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: "#ec268f",
              height: "10rem",
              borderWidth: "0.06em 0 0 0.06em",
              borderStyle: "solid",
              borderColor: "rgba(255,255,255,0.7)",
            }}
          >
            <div className="" style={{ textAlign: "center", fontSize: "20px" }}>
            9:00 AM to 12:30 PM
            </div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: "#ec268f",
              padding: "1em !important",
              borderWidth: "0.06em 0.06em 0 0.06em",
              borderStyle: "solid",
              borderColor: "rgba(255,255,255,0.7)",
            }}
          >
            <div className="" style={{ textAlign: "center", fontSize: "20px" }}>
            2:00 PM To 5:00 PM
            </div>
          </td>
        </tr>
        <tr className="no-padding">
          <td
            style={{
              padding: "1em !important",
              width: "19rem",
              textAlign: "center",
              fontSize: "15px",
            }}
            className="no-border"
          >
            <div className="font-weight-bold">Tuesday</div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: "#ec268f",
              height: "10rem",
              borderWidth: "0.06em 0 0 0.06em",
              borderStyle: "solid",
              borderColor: "rgba(255,255,255,0.7)",
            }}
          >
            <div className="" style={{ textAlign: "center", fontSize: "20px" }}>
            9:00 AM to 12:30 PM
            </div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: "#ec268f",
              padding: "1em !important",
              borderWidth: "0.06em 0.06em 0 0.06em",
              borderStyle: "solid",
              borderColor: "rgba(255,255,255,0.7)",
            }}
          >
            <div className="" style={{ textAlign: "center", fontSize: "20px" }}>
            2:00 PM To 5:00 PM
            </div>
          </td>
        </tr>
        <tr className="no-padding">
          <td
            style={{
              padding: '1em !important',
              width: '19rem',
              textAlign: 'center',
              fontSize: '15px',
            }}
            className="no-border"
          >
            <div className="font-weight-bold">Saturday</div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: '#ec268f',
              height: '10rem',
              borderWidth: '0.06em 0 0 0.06em',
              borderStyle: 'solid',
              borderColor: 'rgba(255,255,255,0.7)',
            }}
          >
            <div className="" style={{ textAlign: 'center', fontSize: '20px' }}>
              9:00 AM to 12:30 PM
            </div>
          </td>
          <td
            className="opacity-100 center aligned"
            style={{
              backgroundColor: '#ec268f',
              padding: '1em !important',
              borderWidth: '0.06em 0.06em 0 0.06em',
              borderStyle: 'solid',
              borderColor: 'rgba(255,255,255,0.7)',
            }}
          >
            <div className="" style={{ textAlign: 'center', fontSize: '20px' }}>
            2:00 PM To 5:00 PM
            </div>
          </td>
        </tr>
        <tr className="no-padding">
          <td
            style={{
              padding: '1em !important',
              width: '19rem',
              textAlign: 'center',
              fontSize: '15px',
            }}
            className="no-border"
          >
            <div className="font-weight-bold">Sunday</div>
          </td>
          <td
            style={{
              padding: '1em !important',
              borderWidth: '0.06em 0 0.06em 0.06em',
              borderStyle: 'solid',
              borderColor: 'rgba(255,255,255,0.7)',
              borderBottomLeftRadius: '.28571429rem',
            }}
          ></td>
          <td
            style={{
              padding: '1em !important',
              borderWidth: '0.06em 0.06em 0.06em 0.06em',
              borderStyle: 'solid',
              borderColor: 'rgba(255,255,255,0.7)',
              borderBottomRightRadius: '.28571429rem',
            }}
          ></td>
        </tr>
       


     </tbody>

</table>

</div>

    </div>

    </div>
    {/* <div class="container-fluid">
    <div class="row">
    <div className="col-md-6" style={{backgroundColor: "#f5f5f5"}}>
      <div style={{marginTop: "20%", marginLeft: "20px"}}>
        <img src="image/gynecologist.svg" className="img-fluid" />
      </div>
    </div>
    <div className="col-md-6">
      <div style={{ marginTop: "20%", marginLeft: "18%" }}>
        <h2 style={{ fontSize: "3rem", color: "black", fontFamily: "Poppins, sans-serif!important" }} className="mb-3">LATEST ARTICLE</h2><br />
        <h1 style={{ color: "#4183c4", fontSize: "33px" }}>Anomaly scan or TIFFA scan</h1>
        <h3 className="mt-3" style={{ color: "#ec268f", fontSize: "22px" }}> Importance <span className="inline divider">|</span> Meaning <span className="inline divider">|</span> When <span className="inline divider">|</span> Why </h3><br /><br /><br />
        <h3 style={{ color: "#000" }}>By</h3>
        <h1 className="" style={{ fontSize: "20px", color: "#0088a5", lineHeight: "110%", fontWeight: 500 }}> Dr. Namita Chandra Verma (Gynecologist & Infertility Specialist) </h1>

        <br />
        <div className="d-flex">

          <div className="content ms-2 mb-4" style={{ fontSize: "18px" }}> During the second trimester,
                             one of the vital and necessary pregnancy ultrasound scan is the Anomaly scan or TIFFA scan (Targeted Imaging for Fetal Anomalies). 
                            This is usually done within 18 to 20 weeks of gestational age.
                             This scan targets checking the baby's physical development, growth, and how the baby is doing and detecting the abnormalities, if any. 
                             Read this article to learn more about an Anomaly scan or TIFFA scan, its meaning, importance, schedule, and report.</div>
        </div> */}

        {/* <div style={{ maxWidth: 265, marginTop: 22 }}>
          <a title="Book appointment with Dr. Varshali Mali (MBBS, DNB). Gynecologist &amp; Pregnancy Doctor" className="book-an-appointment-link" style={{ color: "#4183c4", textDecoration: "none", backgroundColor: "transparent" }}>
            <div className="svg-button svg-dvc-pink-button" style={{ height: 42, cursor: "pointer" }}>
              <svg height="42" width="265" xmlns="http://www.w3.org/2000/svg">
                <rect height="100%" width="100%" className="svg-button-background" style={{ stroke: "#ec268f", fill: "#ec268f" }}></rect>
                <rect height="100%" width="17%" x="83%" className="svg-button-icon-background" style={{ fill: "#000", fillOpacity: .05 }}></rect>
                <g>
                  <text className="svg-button-text" x="20" y="65%">Book an appointment</text>
                </g>
                <g>
                  <text className="svg-button-icon" style={{ fill: "#fff" }} x="89%" y="65%"></text>
                </g>
              </svg>
            </div>
          </a>
        </div> */}
      {/* </div>
    </div>
    </div>

    </div> */}
    
    
   </>
  )
}

export default Doctors