import React from 'react'
import { useEffect } from 'react'


export default function Bladder() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
  Bladder Cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/BC.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         What is bladder cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Bladder cancer is a relatively rare form of cancer that starts in the lining of your bladder. Your bladder is a small hollow organ that holds your pee (urine). Healthcare providers have many ways to treat bladder cancer, including surgery to remove bladder cancer. Bladder cancer may come back after treatment, so people with bladder cancer should be vigilant about following up with their healthcare providers.
<br/>
Healthcare providers can treat early-stage bladder cancer — cancer that’s found and treated before it can spread — but about 75% of early-stage bladder cancers come back.
 
 
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         How does this condition affect my body?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Your bladder is a triangle-shaped organ that’s centered between your hip bones, above your urethra and below your kidneys. Pee from your kidneys drains into your bladder, which is lined with tissue called urothelium. Urothelium is made of cells that stretch when your bladder fills with pee and collapses when it’s empty. (Your bladder can hold about 2 cups of pee.)
<br/>
Bladder cancer happens when certain cells in the tissue lining your bladder mutate or change, becoming abnormal cells that multiply and cause tumors in your bladder. Left untreated, bladder cancer may grow through your bladder walls to nearby lymph nodes and then other areas of your body, including your bones, lungs or liver.
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
           What are bladder cancer types?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            
             There are three types of bladder cancer. Each type is named for the cells that line the wall of your bladder where the cancer started. Bladder cancer types include:<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Transitional cell carcinoma:</span>This cancer starts in transitional cells in the inner lining of your bladder wall. About 90% of all bladder cancers are transitional. In this cancer type, abnormal cells spread from the inner lining to other layers deep in your bladder or through your bladder wall into fatty tissues that surround your bladder. This bladder cancer type is also known as urothelial bladder cancer.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Squamous cell carcinoma:</span>Squamous cells are thin, flat cells that line the inside of your bladder. This bladder cancer accounts for about 5% of bladder cancers and typically develops in people who’ve had long bouts of bladder inflammation or irritation.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Adenocarcinoma:</span>Adenocarcinoma cancers are cancers in the glands that line your organs, including your bladder. This is a very rare type of bladder cancer, accounting for 1% to 2% of all bladder cancers.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Small cell carcinoma of the bladder:</span>This extremely rare type of bladder cancer affects about 1,000 people in the U.S.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Sarcoma:</span>Rarely, soft tissue sarcomas start in bladder muscle cells.
Healthcare providers may also categorize bladder cancer as being noninvasive, non-muscle-invasive or muscle-invasive.

<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Noninvasive: </span>This bladder cancer may be tumors in a small section of tissue or cancer that’s only on or near the surface of your bladder.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Non-muscle-invasive:</span>This refers to bladder cancer that’s moved deeper into your bladder but hasn’t spread to muscle.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Muscle-invasive:</span> This bladder cancer has grown into bladder wall muscle and may have spread into the fatty layers or tissues on organs outside of your bladder.<br/>
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What’s usually the first symptom of bladder cancer?
             </h3>
             <img
               src="image/BC2.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Blood in your pee (urine) is the most common bladder cancer symptom. That said, simply having blood in your pee isn’t a sure sign of bladder cancer. Other conditions cause this issue, too. But you should contact a healthcare provider whenever you spot blood in your pee. Other bladder cancer symptoms include:<br/>
             - Visible blood in your pee (hematuria): Healthcare providers can also spot microscopic amounts of blood in pee when they do a urinalysis.
             <br/> <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Pain when you pee (dysuria):</span> This is a burning or stinging sensation that you may feel when you start to pee or after you pee. Men and DMAB may have pain in their penises before or after peeing.
         <br/>
         <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Needing to pee a lot:</span>Frequent urination means you’re peeing many times during a 24-hour period.<br/>
         <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Having trouble peeing: </span>The flow of your pee may start and stop or the flow may not be as strong as usual.<br/>
         <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Persistent bladder infections: </span> Bladder infections and bladder cancer symptoms have common symptoms. Contact your healthcare provider if you have a bladder infection that doesn’t go away after treatment with antibiotics.<br/>
             </p>
       
           </div>
 
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What are the kidney cancer stages?
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Most cancers are grouped by stage, a description of cancer that aids in planning treatment. The stage of a cancer is based on:<br/>
 
             The location and size of the tumor.<br/>
 The extent to which your lymph nodes are affected.<br/>
 The degree to which the cancer spread, if at all, to other tissue and organs.<br/>
 Your healthcare provider uses information from various tests, including CT, MRI and biopsy, to determine the stage of cancer.<br/>
 <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage I:</span>The tumor is 7 centimeters (cm) across or smaller and is only in your kidney. It hasn’t spread to lymph nodes or other tissue. (Lymph nodes are small “filters” that trap germs and cancer cells and store infection-fighting cells.).<br/>
 <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage II: </span>The tumor is larger than 7 cm across but is still only in your kidney. It hasn’t spread to lymph nodes or other tissue.<br/>
 <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage III:</span>The tumor has spread to your major blood vessels — your renal vein and inferior vena cava — or into the tissue surrounding your kidney or to nearby lymph nodes.<br/>
 <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage IV:</span>The tumor has spread outside of your kidney to your adrenal gland (the small gland that sits on top of your kidney), or to distant lymph nodes or other organs.<br/>
 Tumors are also graded, which is a way of rating a tumor based on how abnormal its cells look. Tumor grading can also tell your healthcare provider how fast the tumor is likely to grow. Tumors whose cells don’t look like normal cells and divide rapidly are called high-grade tumors. High-grade tumors tend to grow and spread more quickly than low-grade tumors.
             </p>
             
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Bladder Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
