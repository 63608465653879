import React from 'react'
import { useEffect } from 'react'


export default function KidneyCancer() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
   <section style={{ backgroundColor: "#f5f5f5" }}>
    <h1
      style={{
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: "2rem",
        textShadow: "var(--text-shadow)",
        textTransform: "uppercase",
        color: "var(--black)",
        fontSize: "5rem",
        letterSpacing: ".4rem",
      }}
    >
  Lung Cancer
      {/* <span style="    text-transform: uppercase;
      color: var(--green);"> Services</span> */}
    </h1>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src="image/lung2.jpg"
              className="img-fluid"
              style={{
                width: "100%",
                height: "500px",
                marginBottom: 20,
                marginTop: 20,
              }}
            />
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
      What is lung cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15 }}>
            Lung cancer is a disease caused by uncontrolled cell division in your lungs. Your cells divide and make more copies of themselves as a part of their normal function. But sometimes, they get changes (mutations) that cause them to keep making more of themselves when they shouldn’t. Damaged cells dividing uncontrollably create masses, or tumors, of tissue that eventually keep your organs from working properly.<br/>
            Lung cancer is the name for cancers that start in your lungs — usually in the airways (bronchi or bronchioles) or small air sacs (alveoli). Cancers that start in other places and move to your lungs are usually named for where they start (your healthcare provider may refer to this as cancer that’s metastatic to your lungs).

            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
What are the types of lung cancer?            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            There are many cancers that affect the lungs, but we usually use the term “lung cancer” for two main kinds: non-small cell lung cancer and small cell lung cancer.
            <br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Non-small cell lung cancer (NSCLC)</span>
<br/>Non-small cell lung cancer (NSCLC) is the most common type of lung cancer. It accounts for over 80% of lung cancer cases. Common types include adenocarcinoma and squamous cell carcinoma. Adenosquamous carcinoma and sarcomatoid carcinoma are two less common types of NSCLC<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Small cell lung cancer (SCLC)</span>
Small cell lung cancer (SCLC) grows more quickly and is harder to treat than NSCLC. It’s often found as a relatively small lung tumor that’s already spread to other parts of your body. Specific types of SCLC include small cell carcinoma (also called oat cell carcinoma) and combined small cell carcinoma.
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Other types of cancer in the lungs</span>
Other types of cancer in the lungs
Other types of cancer can start in or around your lungs, including lymphomas (cancer in your lymph nodes), sarcomas (cancer in your bones or soft tissue) and pleural mesothelioma (cancer in the lining of your lungs). These are treated differently and usually aren’t referred to as lung cancer.


            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
     What are the symptoms of lung cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>Most lung cancer symptoms look similar to other, less serious illnesses. Many people don’t have symptoms until the disease is advanced, but some people have symptoms in the early stages. For those who do experience symptoms, it may only be one or a few of these:
          <br/> - A cough that doesn’t go away or gets worse over time.<br/>
- Trouble breathing or shortness of breath (dyspnea).<br/>
- Chest pain or discomfort.<br/>
- Wheezing.<br/>
- Coughing up blood (hemoptysis).<br/>
- Hoarseness.<br/>
- Loss of appetite.<br/>
- Unexplained weight loss.<br/>
- Unexplained fatigue (tiredness).<br/>
- Shoulder pain.<br/>
- Swelling in the face, neck, arms or upper chest (superior vena cava syndrome).<br/>
- Small pupil and drooping eyelid in one eye with little or no sweating on that side of your face (Horner’s syndrome).<br/>
            </p>

          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >How is lung cancer treated?
            </h3>
            <img
              src="image/lung.png"
              className="img-fluid"
              style={{
                marginBottom: 20,
                width: "100%",
                height: "300px",
                marginTop: 20,
              }}
            />
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Treatments for lung cancer are designed to get rid of cancer in your body or slow down its growth. Treatments can remove cancerous cells, help to destroy them or keep them from multiplying or teach your immune system to fight them. Some therapies are also used to reduce symptoms and relieve pain. Your treatment will depend on the type of lung cancer you have, where it is, how far it’s spread and many other factors.
            <br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>What medications/treatments are used in lung cancer?</span>
            <br></br>
            Lung cancer treatments include surgery, radiofrequency ablation, radiation therapy, chemotherapy, targeted drug therapy and immunotherapy.
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Surgery</span><br/>
            NSCLC that hasn’t spread and SCLC that’s limited to a single tumor can be eligible for surgery. Your surgeon might remove the tumor and a small amount of healthy tissue around it to make sure they don’t leave any cancer cells behind. Sometimes they have to remove all or part of your lung (resection) for the best chance that the cancer won’t come back.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Radiofrequency ablation</span><br/>
            NSCLC tumors near the outer edges of your lungs are sometimes treated with radiofrequency ablation (RFA). RFA uses high-energy radio waves to heat and destroy cancer cells.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Radiation therapy</span><br/>
            Radiation uses high energy beams to kill cancer cells. It can be used by itself or to help make surgery more effective. Radiation can also be used as palliative care, to shrink tumors and relieve pain. It’s used in both NSCLC and SCLC.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Chemotherapy</span><br/>
            Chemotherapy is often a combination of multiple medications designed to stop cancer cells from growing. It can be given before or after surgery or in combination with other types of medication, like immunotherapy. Chemotherapy for lung cancer is usually given through an IV.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Targeted drug therapy</span><br/>
            In some people with NSCLC, lung cancer cells have specific changes (mutations) that help the cancer grow. Special drugs target these mutations to try to slow down or destroy cancer cells. Other drugs, called angiogenesis inhibitors, can keep the tumor from creating new blood vessels, which the cancer cells need to grow.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Immunotherapy</span><br/>
            Our bodies usually recognize cells that are damaged or harmful and destroy them. Cancer has ways to hide from the immune system to keep from being destroyed. Immunotherapy reveals cancer cells to your immune system so your own body can fight cancer.<br/>
            <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Treatments to ease symptoms (palliative care)
</span><br/>
Some lung cancer treatments are used to relieve symptoms, like pain and difficulty breathing. These include therapies to reduce or remove tumors that are blocking airways, and procedures to remove fluid from around your lungs and keep it from coming back.<br/>







            
       
            </p>
           
          </div>

          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >How can I prevent lung cancer?
            </h3>
           
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Since we don’t know what causes most cancers for sure, the only preventative measures are focused on reducing your risk. Some ways to reduce your risk include:<br/>
            - Don’t smoke or quit smoking if you do. Your risk of lung cancer starts coming down within five years of quitting.
<br/>- Avoid second hand smoke and other substances that can harm your lungs.<br/>

- Eat a healthy diet and maintain a weight that’s healthy for you. Some studies suggest that eating fruits and vegetables (two to six-and-a-half cups per day) can help reduce your risk of cancer.<br/>
- Get screened for lung cancer if you’re at high risk.<br/>
<span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Lung cancer screening</span><br/>
You can increase your chances of catching cancer in its earliest stages with screening tests. You’re eligible for lung cancer screening if you meet all of these requirements:<br/>
- You’re between the ages of 50 and 80.
- You either currently smoke or have quit smoking within the last 15 years.
- You have a 20 pack-year smoking history (number of packs of cigarettes per day times the number of years you smoked).

            </p>
            
          </div>
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Estimated Time Required:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Most couples are able to get pregnant within six months to a year.
              If you're unable to conceive after a full year of trying, it's a
              good idea to consult a fertility specialist. Sometimes there's an
              obvious cause of infertility, like a physical problem with the
              ovaries, uterus, or testicles.
            </p>
          </div> */}
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Number Of Visits And Hospitalization:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              If you have been trying to conceive for more than a year (or six
              months if you are over 35 years old), it is recommend that you
              undergo an evaluation. A fertility specialist can help determine
              why you are having difficulty and offer possible treatment options
              to help you conceive.
            </p>
          </div> */}
          {/* <div>
                  <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
              </div> */}
          <a
            href="services.html"
            target="_blank"
            className="btn"
            style={{ marginBottom: 20 }}
          >
            {" "}
            Lungs Cancer{" "}
            <span className="fas fa-chevron-right" />{" "}
          </a>
          <h3
            style={{
              color: "brown",
              fontWeight: "bolder",
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            For further queries related to the treatment, Please call
            +91-9587025972 or write to naveen.vermab4@gmail.com
          </h3>
        </div>
      </div>
    </div>
  </section>
  {/* booking section ends */}
  {/* Remove the container if you want to extend the Footer to full width. */}
  <div className="container my-5"></div>


   </>
  )
}
