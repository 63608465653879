import React from 'react'

const AdvanceUltrasonography = () => {
  return (
  <>
  ;<section style={{ backgroundColor: "#f5f5f5" }}>
  <h1
    style={{
      textAlign: "center",
      fontWeight: "bold",
      paddingBottom: "2rem",
      textShadow: "var(--text-shadow)",
      textTransform: "uppercase",
      color: "var(--black)",
      fontSize: "5rem",
      letterSpacing: ".4rem",
    }}
  >
    Advanced Ultrasonography
    {/* <span style="    text-transform: uppercase;
  color: var(--green);"> Services</span> */}
  </h1>
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-9">
        <div>
          <img
            src="image/ultrasono.jpg"
            className="img-fluid"
            style={{
              width: "84rem",
              height: "30rem",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Ultra Sonography(USG)
          </h3>
          <p style={{ color: "black", fontSize: 15 }}>
            Advanced Ultrasound Examinations/Fetal Diagnosis. A Detailed Anatomy
            ultrasound is a special type of ultrasound to assess the fetus for
            structural/anatomical abnormalities and its development. This is
            usually performed at a perinatal center where more expertise in
            ultrasound scanning is available. <br />
            <br />
            The scan is performed by an experienced sonographer and read by a
            qualified and experienced doctor who specializes in high risk
            pregnancies, called a Perinatologist. The scan is usually performed
            between 18-20 weeks of pregnancy. There are many situations when a
            Detailed Anatomy scan is ordered by your OB. Detailed scanning of
            the fetus for any congenital anomalies and growth abnormalities is
            done on top of the basic documentations obtained at a Routine
            Ultrasound examination.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Success Rate Of Ultrasonography:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Surgical Success is achieved in 99% of patients in the ultrasound
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Process Of Ultrasonography :
          </h3>
          <img
            src="image/ultrasono1.jpg"
            className="img-fluid"
            style={{
              marginBottom: 20,
              width: "84rem",
              height: "30rem",
              marginTop: 20,
            }}
          />
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            {/* <span style="color: brown; font-weight: bold;">ovarian stimulation, egg collection, fertilization, embryo culture and embryo transfer.</span> */}
            The patient changes into a hospital gown and may be advised to lie
            down on the examination table with a section of the body exposed for
            the test. A special lubricating jelly will be applied to the skin by
            an ultrasound technician called a sonographer to prevent friction
            and rub the ultrasound transducer on the skin. The jelly helps in
            the transmission of the sound waves.
          </p>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            High-frequency sound waves sent through the body by the transducer,
            which reverberate as they hit a dense object like a bone or an
            organ. The reverberations are then reflected into a computer. A
            person might need to change positions depending on the area being
            examined so the technician can have better access.
            <br />
            <br />
            The gel will be cleaned off of the skin after completing the
            procedure. It typically lasts less than 30 minutes and depends on
            the area being examined. As soon as the procedure is completed, the
            patient will be free to go about with their normal activities.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Estimated Time Required:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            These machines use high-frequency sound waves to produce echoes that
            are then transformed into images. You can expect an ultrasound to
            take about 45 minutes and you may need to be prepped. The patient
            will be called, or a follow-up will be scheduled for the discussions
            regarding the findings from the examination. The patient may have to
            undergo other diagnostic techniques like MRI, CT scan, or a biopsy
            sample of tissue depending on the area examined if anything abnormal
            turns up on the ultrasound.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Number Of Visits And Hospitalization:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Most healthy women receive two ultrasound scans during pregnancy.
            The first is, ideally, in the first trimester to confirm the due
            date, and the second is at 18-22 weeks to confirm normal anatomy and
            the sex of the baby.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Cost Of Ultrasonography
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            The cost of an Ultrasonography cycle varies from Rs.900/- to
            Rs.2500/-, depending on the case. On an average, it is about
            Rs.1500/-.
          </p>
        </div>
        {/* <a href="services.html" target="_blank" class="btn" style="margin-bottom: 20px;"> Test Tube Baby/ IVF Calculator <span class="fas fa-chevron-right"></span> </a> */}
        <h3
          style={{
            color: "brown",
            fontWeight: "bolder",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          For further queries related to the treatment, Please call
          +91-9871250235 or write to contact@advancefertility.in
        </h3>
      </div>
    </div>
  </div>
</section>

  </>
  )
}

export default AdvanceUltrasonography