import React from 'react'

const Itmales = () => {
  return (
  <>
  ;<section style={{ backgroundColor: "#f5f5f5" }}>
  <h1
    style={{
      textAlign: "center",
      fontWeight: "bold",
      paddingBottom: "2rem",
      textShadow: "var(--text-shadow)",
      textTransform: "uppercase",
      color: "var(--black)",
      fontSize: "5rem",
      letterSpacing: ".4rem",
    }}
  >
    Infertility Treatment in Males
    {/* <span style="    text-transform: uppercase;
      color: var(--green);"> Services</span> */}
  </h1>
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-9">
        <div>
          <img
            src="image/itmmales.jpg"
            className="img-fluid"
            style={{
              width: "84rem",
              height: "30rem",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Infertility Treatment in Males
          </h3>
          <p style={{ color: "black", fontSize: 15 }}>
            Male infertility refers to a sexually mature male's inability to
            impregnate a fertile female. In humans it accounts for 40–50% of
            infertility. It affects approximately 7% of all men. Male
            infertility is commonly due to deficiencies in the semen, and semen
            quality is used as a surrogate measure of male fecundity. More
            recently, advance sperm analyses that examine intracellular sperm
            components are being developed.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Who Needs Infertility Treatment in Males?
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Even men who have low sperm production as a cause of no sperm in the
            ejaculate can be treated with surgery to find sperm, together with
            assisted reproduction, since several million sperm have to be made
            in the testicles before sperm survive to make it into the semen.
            Ones with an untreated acute infectious disease. Ones with untreated
            reproductive tract infections.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Success Rate Of Infertility Treatment in Males:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Through the ICSI procedure, many couples with difficult male factor
            infertility problems have achieved pregnancy. Fertilization rates of
            70 percent to 80 percent of all eggs injected are currently being
            achieved, and pregnancy rates are comparable to those seen with IVF
            in couples with no male factor infertility.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Process Of Infertility Treatment in Males:
          </h3>
          <img
            src="image/itmmales1.jpg"
            className="img-fluid"
            style={{
              marginBottom: 20,
              width: "84rem",
              height: "30rem",
              marginTop: 20,
            }}
          />
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            The Infertility Treatment process in Males consists of
            <span style={{ color: "brown", fontWeight: "bold" }}>
              {" "}
              assisted reproductive technologies.
            </span>
            Treatment for oligospermia is centered around underlying causes,
            such as endocrine and systematic disorders that can cause
            hypogonadism.
          </p>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Combinations of vitamins and minerals, including selenium, co-enzyme
            Q10, L-carnitine, folic acid, zinc, eicosapentaenoic acid (EPA), and
            docosahexaenoic acid (DHA), have been shown to improve male
            infertility, but due to the low amounts of studies and participants,
            more clinical studies are needed. Folate in combination with zinc
            supplementation was shown to have a statistically significant effect
            on sperm concentration and morphology when compared to placebo.
            There is evidence suggesting a significant association between
            vitamin D serum concentrations and the quality of sperm in men,
            characterized by the sperm's motility and progress motility.
            <br />
            <br />
            As of September 2017, mesenchymal stem cell therapy for infertility
            has been studied in animals, but has not entered clinical
            trials.[26] Stem cells collected from bone marrow and umbilical cord
            have shown the most ability to rehabilitate fertility in animals,
            but more studies are needed to determine efficacy.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Estimated Time Required:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Sperm take about 74 days to mature, which means that men who do make
            lifestyle changes only need to wait about three months before seeing
            improvements in sperm quality–increasing their chances for
            reproductive success. Peak male fertility is around 25-29 years old.
            Sperm quality begins to decline at 30. At 45, men begin to
            experience a significant decrease in semen volume. Older men can
            also take longer to conceive a child.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Number Of Visits And Hospitalization:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            If you have been trying to conceive for more than a year (or six
            months if you are over 35 years old), it is recommend that you
            undergo an evaluation. A fertility specialist can help determine why
            you are having difficulty and offer possible treatment options to
            help you conceive.
          </p>
        </div>
        {/* <div>
                  <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
              </div> */}
        <a
          href="services.html"
          target="_blank"
          className="btn"
          style={{ marginBottom: 20 }}
        >
          {" "}
          Test Tube Baby/ IVF Calculator{" "}
          <span className="fas fa-chevron-right" />{" "}
        </a>
        <h3
          style={{
            color: "brown",
            fontWeight: "bolder",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          For further queries related to the treatment, Please call
          +91-9871250235 or write to contact@advancefertility.in
        </h3>
      </div>
    </div>
  </div>
</section>

  </>
  )
}

export default Itmales