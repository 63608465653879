import React from 'react'
import { useNavigate } from 'react-router-dom'

const About = () => {
    const navigate=useNavigate()
  return (
    <>
        <section className="about" id="about">

<h1 className="heading"> <span>About</span> Dr. Naveen</h1>

<div className="row">

    <div className="image">
        <img src="image/drnav2.jpeg" alt="" />
    </div>

    <div className="content">
        <h1>MBBS, MS(General Surgery) and MCH Surgical Oncology</h1>
        <p>Dr. Naveen Verma is an accomplished and skilled oncosurgeon, who specializes in the treatment of cancer through surgical intervention. His young age belies his expertise and extensive training, which he received at the esteemed Gujrat Cancer & Research Institute (GCRI) in Ahmedabad, India.
           Dr. Verma's clinical acumen is exceptional, and his ability to make sound judgments and decisions is widely recognized. He has achieved a remarkable success rate in performing various cancer surgeries, owing to his comprehensive knowledge and proficiency in the field.</p>
        <p>In addition to his surgical prowess, Dr. Verma is known for his amiable personality and excellent communication skills. He has been invited to speak at various national and international conferences, including the European Cancer Congress, NALCCON, ICON, and AVCLC.
           Dr. Verma is also a prolific writer, having published numerous papers and publications in leading surgical oncology journals. Furthermore, 
           he has been an active participant and speaker at conferences organized by the Indian Medical Association, cementing his status as a respected member of the medical community.
        </p>
        {/* <a  className="btn"> learn more <span className="fas fa-chevron-right" onClick={()=>navigate('/doctors')}></span> </a> */}
    </div>

</div>

</section>
    </>
  )
}

export default About