import React from 'react'
import { useEffect } from 'react';


export default function Stomach() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
   colorectal cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/colorectal.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
    What Is Colorectal Cancer?

             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Colorectal cancer starts in the colon or the rectum. These cancers can also be called colon cancer or rectal cancer, depending on where they start. Colon cancer and rectal cancer are often grouped together because they have many features in common.
             <br/>
             <br/>
             <span style={{fontWeight:"bold"}}>The colon and rectum</span>
             <br/>
             <br/>
             To understand colorectal cancer, it helps to know about the normal structure and function of the colon and rectum.
             <br/>
             <br/>
The colon and rectum make up the large intestine (or large bowel), which is part of the digestive system, also called the gastrointestinal (GI) system (see illustration below).
<br/>
<br/>

Most of the large intestine is made up of the colon, a muscular tube about 5 feet (1.5 meters) long. The parts of the colon are named by which way the food is traveling through them.  
<br/>
<br/>
- The first section is called the ascending colon. It starts with a pouch called the cecum, where undigested food comes in from the small intestine. It continues upward on the right side of the abdomen (belly).
<br/>
<br/>

- The second section is called the transverse colon. It goes across the body from the right to the left side.
<br/>
<br/>

- The third section is called the descending colon because it descends (travels down) on the left side.
<br/>
<br/>

- The fourth section is called the sigmoid colon because of its “S” shape. The sigmoid colon joins the rectum, which then connects to the anus.
<br/>
<br/>
 
The ascending and transverse sections together are called the proximal colon. The descending and sigmoid colon are called the distal colon.
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >How do the colon and rectum work?

             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             The colon absorbs water and salt from the remaining food matter after it goes through the small intestine (small bowel). The waste matter that's left after going through the colon goes into the rectum, the final 6 inches (15cm) of the digestive system. It's stored there until it passes through the anus. Ring-shaped muscles (also called a sphincter) around the anus keep stool from coming out until they relax during a bowel movement.
                    </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
How does colorectal cancer start?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              <br/>
              <br/>
           <span style={{fontWeight:"bold"}}>Polyps in the colon or rectum</span>
           <br/>
           <br/>
           Most colorectal cancers start as a growth on the inner lining of the colon or rectum. These growths are called polyps.

           <br/>
           <br/>

           Some types of polyps can change into cancer over time (usually many years), but not all polyps become cancer. The chance of a polyp turning into cancer depends on the type of polyp it is. There are different types of polyps.
           <br/>
           <br/>
           <br/>
           <span style={{fontWeight:"bold"}}>Adenomatous polyps (adenomas):</span>
 These polyps sometimes change into cancer. Because of this, adenomas are called a pre-cancerous condition. The 3 types of adenomas are tubular, villous, and tubulovillous. 
<br/>
           <br/>
           <span style={{fontWeight:"bold"}}>Hyperplastic polyps and inflammatory polyps:</span> These polyps are more common, but in general they are not pre-cancerous. Some people with large (more than 1cm) hyperplastic polyps might need colorectal cancer screening with colonoscopy more often. 
<br/>
           <br/>
           <span style={{fontWeight:"bold"}}>Sessile serrated polyps (SSP) and traditional serrated adenomas (TSA): </span>These polyps are often treated like adenomas because they have a higher risk of colorectal cancer.
<br/>
<br/>
Other factors that can make a polyp more likely to contain cancer or increase someone’s risk of developing colorectal cancer include:
<br/>
<br/>
- If a polyp larger than 1 cm is found
<br/>
<br/>
- If more than 3 polyps are found
<br/>
<br/>
- If dysplasia is seen in the polyp after it's removed. Dysplasia is another pre-cancerous condition. It means there's an area in a polyp or in the lining of the colon or rectum where the cells look abnormal, but they haven't become cancer.
<br/>
<br/>
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
How colorectal cancer spreads
             </h3>
             <img
               src="image/colorectal1.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             If cancer forms in a polyp, it can grow into the wall of the colon or rectum over time. The wall of the colon and rectum is made up of many layers. Colorectal cancer starts in the innermost layer (the mucosa) and can grow outward through some or all of the other layers (see picture below).
<br/>
<br/>
When cancer cells are in the wall, they can then grow into blood vessels or lymph vessels (tiny channels that carry away waste and fluid). From there, they can travel to nearby lymph nodes or to distant parts of the body.
<br/>
<br/>

The stage (extent of spread) of a colorectal cancer depends on how deeply it grows into the wall and if it has spread outside the colon or rectum. For more on staging, see Colorectal Cancer Stages.
<br/>
<br/>
   
             </p>
       
           </div>
 
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Types of cancer in the colon and rectum
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Most colorectal cancers are adenocarcinomas. These cancers start in cells that make mucus to lubricate the inside of the colon and rectum. When doctors talk about colorectal cancer, they're almost always talking about this type. Some sub-types of adenocarcinoma, such as signet ring and mucinous, may have a worse prognosis (outlook) than other subtypes of adenocarcinoma.  
<br/>
<br/>
Other, much less common types of tumors can also start in the colon and rectum. These include:
<br/>
<br/>
- Carcinoid tumors. These start from special hormone-making cells in the intestine. See Gastrointestinal Carcinoid Tumors.
<br/>
<br/>
- Gastrointestinal stromal tumors (GISTs) start from special cells in the wall of the colon called the interstitial cells of Cajal. Some are benign (not cancer). These tumors can be found anywhere in the digestive tract, but are not common in the colon. See Gastrointestinal Stromal Tumor (GIST).
<br/>
<br/>
- Lymphomas are cancers of immune system cells. They mostly start in lymph nodes, but they can also start in the colon, rectum, or other organs. Information on lymphomas of the digestive system can be found in Non-Hodgkin Lymphoma.
<br/>
<br/>
- Sarcomas can start in blood vessels, muscle layers, or other connective tissues in the wall of the colon and rectum. Sarcomas of the colon or rectum are rare. See Soft Tissue Sarcoma.
             </p>
             
           </div>
           <div>
            <div>
            <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
              Stages in Colorectal
             </h3>
             <img src="/image/rectal.jpeg" style={{width:"100%"}}></img>
             <br/>
             <br/>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              <br/>
              <br/>
             <span style={{fontWeight:"bold"}}>Stage I: </span>The cancer has grown through the mucosa and has invaded the muscular layer of the colon or rectum. It has not spread into nearby tissue or lymph nodes (T1 or T2, N0, M0).
             <br/>
             <br/>
             
             <span style={{fontWeight:"bold"}}>Stage IIA:</span> The cancer has grown through the wall of the colon or rectum but has not spread to nearby tissue or to the nearby lymph nodes (T3, N0, M0).
             <br/>
             <br/>
             
             <span style={{fontWeight:"bold"}}>Stage IIB: </span>The cancer has grown through the layers of the muscle to the lining of the abdomen, called the visceral peritoneum. It has not spread to the nearby lymph nodes or elsewhere (T4a, N0, M0).
             <br/>
             <br/>
             
             <span style={{fontWeight:"bold"}}>Stage IIC: </span> The tumor has spread through the wall of the colon or rectum and has grown into nearby structures. It has not spread to the nearby lymph nodes or elsewhere (T4b, N0, M0).
             <br/>
             <br/>

</p>
            </div>
             {/* <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p> */}
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Bones Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
