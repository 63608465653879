import logo from './logo.svg';
import './App.css';
import NavbarO from './components/NavbarO';
import Footer from './components/Footer';
import Home from './components/Home';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import About from './components/About';
import Doctors from './components/Doctors';
import Appoinment from './components/Appoinment';
import Gallery from './components/Gallery';
import Ivf from './components/services/Ivf';
import Itfemails from './components/services/Itfemails';
import Itmales from './components/services/Itmales';
import IUI from './components/services/IUI';
import Donor from './components/services/Donor';
import Gynecology from './components/Gynecology';
import AdvanceUltrasonography from './components/services/AdvanceUltrasonography';
import KidneyCancer from './components/KidneyCancer';
import LungsCancer from "./components/LungsCancer"
import LiverCancer from './components/LiverCancer';
import Pancreatic from './components/Pancreatic';
import Esophageal from "./components/Esophageal"
import Bladder from './components/services/Bladder';
import Bones from './components/services/Bones';
import Orell from "./components/Orell"
import Stomach from './components/Stomach';
import Colorectal from './components/Colorectal';

function App() {
  return (
    <>
      <BrowserRouter>
      <NavbarO />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/appoinment" element={<Appoinment />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/lvf" element={<Ivf />} />
          <Route path="/Itfemails" element={<Itfemails />} />
          <Route path="/itmales" element={<Itmales />} />
          <Route path='/iui' element={<IUI />} />
          <Route path='/donor' element={<Donor />} />
          <Route path='/gynecology' element={<Gynecology />} />
          <Route path='/advanceultragraphy' element={<AdvanceUltrasonography />} />
          <Route path='/kidneycancer' element={<KidneyCancer/>} />
          <Route path='/lungscancer' element={<LungsCancer/>} />
          <Route path='/livercancer' element={<LiverCancer/>} />
          <Route path='/pancreatic' element={<Pancreatic/>} />
          <Route path='/esophageal' element={<Esophageal/>} />
          <Route path='/bladder' element={<Bladder/>} />
          <Route path='/bones' element={<Bones/>} />
          <Route path='/orell' element={<Orell/>} />
          <Route path='/stomach' element={<Stomach/>} />
          <Route path='/colorectal' element={<Colorectal/>} />



        </Routes>
      <Footer />
      </BrowserRouter>

    </>
  );
}

export default App;
