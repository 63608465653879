import React from 'react'
import { useEffect } from 'react'


export default function Bones() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
Bone Cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/Bone.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
     What is bone cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Bone cancer is the term for several different cancers that develop in the bones. When cancer cells grow in a bone, it can harm normal bone tissue. The type of cell and tissue where cancer begins determines the type of bone cancer.
<br/>
Cancers that form in the bone itself are called primary bone cancers. Many tumors that begin in organs or other parts of the body can spread to the bones, as well as other body parts. These growths are called secondary or metastatic bone cancers. Breast, prostate and lung tumors most commonly metastasize (spread) to the bones.
 
 
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         Where does bone cancer usually start?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             It depends on which type of bone cancer you have. There are four types of primary bone cancer:<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Osteosarcoma:  </span>The most common type of bone cancer, osteosarcoma develops in the cells where new bone tissue forms. It can start in any bone, but it usually begins at the ends of large bones such as the arms and legs. Providers most commonly diagnose osteosarcoma in children and teenagers.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Ewing sarcoma:  </span>Named for the doctor who first described this type of bone cancer, Ewing sarcoma includes many different tumors that have similar qualities and are believed to begin in the same types of cells. These tumors can form in the bones and in surrounding soft tissues. Ewing sarcoma most commonly grows in the hips, ribs and shoulder blades, or on long bones such as the legs.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Chondrosarcoma:  </span>Chondrosarcoma begins in tissue called cartilage. Cartilage is a soft connective tissue that allows movement between bones and joints. Some cartilage becomes bone when the body adds calcium to it. This cancer typically forms in the arm, leg or pelvis bones. Unlike osteosarcoma and Ewing sarcoma, chondrosarcoma occurs more frequently in adults than in younger people.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Chordoma:  </span>This rare tumor begins in the bones of the spine — usually at the base of the spine or the base of the skull. Like chondrosarcoma, chordoma occurs most often in older adults. Men are more likely than women to develop this type of bone cancer.<br/>
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
        What should I know about bone cancer staging?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            
             Staging is determined by the size and location of the tumor, and whether or not cancer has spread to other areas. Primary bone cancer is categorized into four stages:<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage 1:</span> The tumor is low-grade, and the cancer cells are still localized.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage 2:</span>The cancer cells are still localized, but the tumor is high-grade.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Adenocarcinoma:</span>Adenocarcinoma cancers are cancers in the glands that line your organs, including your bladder. This is a very rare type of bladder cancer, accounting for 1% to 2% of all bladder cancers.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage 3:</span>The tumor is high-grade and cancer has spread to other areas within the same bone.<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Stage 4:</span>Cancer had spread from the bone to other areas of the body, such as the lungs or liver.

<br/>
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What are common bone cancer symptoms?
             </h3>
             <img
               src="image/Bone2.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Some people with bone cancer have no symptoms other than feeling a painless lump. For others, a variety of symptoms can develop. These symptoms may also occur because of other conditions, such as arthritis or Lyme disease, which may delay the diagnosis. The most common signs of bone cancer include:<br/>
             - Pain (usually worse at night).<br/>
             - Unexplained swelling.<br/>
             - Difficulty moving around.<br/>
             - Feeling extra tired (fatigue).<br/>
             - Fever.<br/>
             </p>
       
           </div>
 
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What causes bone cancer?
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Experts are not certain what causes bone cancer, but they have found links between bone cancer and other factors. The most important factor is being exposed to radiation or drugs during treatment for other cancers. Some bone cancers occur due to conditions that are passed down in families (hereditary), although this is not usually the case.
             </p>
             
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Bones Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
