import React from 'react'

const IUI = () => {
  return (
   <>
   ;<section style={{ backgroundColor: "#f5f5f5" }}>
  <h1
    style={{
      textAlign: "center",
      fontWeight: "bold",
      paddingBottom: "2rem",
      textShadow: "var(--text-shadow)",
      textTransform: "uppercase",
      color: "var(--black)",
      fontSize: "5rem",
      letterSpacing: ".4rem",
    }}
  >
    Intra Uterine Insemination Services
    {/* <span style="    text-transform: uppercase;
  color: var(--green);"> Services</span> */}
  </h1>
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-9">
        <div>
          <img
            src="image/iui1.jpg"
            className="img-fluid"
            style={{
              width: "84rem",
              height: "30rem",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Intra Uterine Insemination
          </h3>
          <p style={{ color: "black", fontSize: 15 }}>
            IUI stands for in intrauterine insemination. It’s also sometimes
            called donor insemination, alternative insemination, or artificial
            insemination. IUI works by putting sperm cells directly into your
            uterus around the time you’re ovulating, helping the sperm get
            closer to your egg. This cuts down on the time and distance sperm
            has to travel, making it easier to fertilize your egg.
            <br />
            <br />
            IUI is a simple and low-tech procedure, and it can be less expensive
            than other types of fertility treatments. It increases your chances
            of pregnancy, but everyone’s body is different, so there’s no
            guarantee that IUI will work.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            What to expect during IUI treatment?
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Before IUI, you may take fertility medicines that help make your
            eggs mature and ready to be fertilized. Your doctor will do the
            insemination procedure during ovulation (when your ovaries release
            an egg). Sometimes you’ll be given hormones that trigger ovulation.
            They’ll figure out exactly when you’re ovulating and ready for the
            procedure to maximize your chances of getting pregnant.
            <br />
            <br />
            The insemination procedure is done at your doctor’s office or at a
            fertility clinic, and it only takes about 5-10 minutes. It’s pretty
            quick, and you don’t need anesthesia. IUI is usually not painful,
            but some people have mild cramping.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Success Rate of IUI treatment:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            If you are experiencing unexplained fertility with healthy eggs and
            two fallopian tubes, IUI success rates are around 7% to 10% per
            cycle. If you use IUI treatment in combination with fertility
            medications your success rate increases by 15-25%.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Process Of Intra Uterine Insemination :
          </h3>
          <img
            src="image/iuiprocess.jpg"
            className="img-fluid"
            style={{
              marginBottom: 20,
              width: "84rem",
              height: "30rem",
              marginTop: 20,
            }}
          />
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            IUI procedure starts with collecting your blood samples and
            undergoing an ultrasound test to check your condition. If everything
            looks good, your IUI will be scheduled just before you start
            ovulating. Before inseminating the uterus with the sperm, you will
            be administered hCG shots, generally 24 hours before the procedure,
            to increase the chances of insemination.
          </p>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            On the day of the process, the sperm will be prepared and chosen by
            sperm washing process. A catheter containing your sperm will then be
            placed inside your uterus through the cervix. Generally, you will be
            asked to lie horizontally for a few minutes after the procedure is
            performed.
            <br />
            <br />
            IUI is generally opted when the sperm cannot go through the cervix
            of a woman for any reason. So, in cases of low sperm count or
            problems with sperm mobility, this procedure directly introduces the
            best sperm inside the uterus, thus increasing the probability of
            pregnancy. Also, this can be used with women who have semen allergy,
            unexplained infertility issues or a cervical mucus problem.
            <br />
            <br />
            After 2 weeks, you will be asked to take a pregnancy test at home or
            you can have a checkup at the hospital too to see if the procedure
            has been fruitful.
            <br />
            <br />
            <span style={{ color: "brown", fontWeight: "bold" }}>
              There is a small risk of infection following the IUI procedure.
              Your doctor will use sterile instruments, so infection is very
              rare. If medications are used to induce ovulation, there is a risk
              of pregnancy with multiple babies.
            </span>
          </p>
        </div>
        {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      Estimated Time Required And When To Arrive:
                  </h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;">
                      One IVF cycle takes about l5 days from start of period to embryo transfer. In this case, you
                      need<br> to reach 3-4 days before the period starts.<br>
                      Many patients may not be able to spare so much time. If you collect your medications from us
                      in<br> advance your stay can be reduced to a week to 10 days around the time of embryo
                      transfer. In <br>this case, you can reach on the 7th or 8th day of your period.<br>
                      You can travel back 2-3 days after embryo transfer. We are sure that travelling does not
                      reduce <br>the chance of pregnancy.<br>
                      It takes 15 days from the embryo transfer to obtain the pregnancy test result.
                  </p>
              </div> */}
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Number Of Visits And Hospitalization:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            The timeline for the IUI procedure is approximately four weeks
            (around 28 days) from beginning to end. It's about the same length
            as a regular menstrual cycle.
          </p>
        </div>
        {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies
                      from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs.
                      The package includes the hormonal injection used for stimulation, the oral medication and
                      vitamins for husband and wife during IVF stimulation, doctors consultation fees and
                      follicular monitoring during the IVF cycle.</p>
              </div>
              <a href="services.html" target="_blank" class="btn" style="margin-bottom: 20px;"> Test Tube Baby/
                  IVF Calculator <span class="fas fa-chevron-right"></span> </a> */}
        <h3
          style={{
            color: "brown",
            fontWeight: "bolder",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          For further queries related to the treatment, Please call
          +91-9587025972 or write to namita_chandra@yahoo.co.in
        </h3>
      </div>
    </div>
  </div>
</section>

   </>
  )
}

export default IUI