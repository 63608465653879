import React from 'react'
import { useEffect } from 'react'


export default function Orell() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
 oral cavity and oropharyngeal cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/orel.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
     What Are Oral Cavity and Oropharyngeal Cancers?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Oral cavity cancer starts in the mouth. It might also be called oral cancer. Oropharyngeal cancer starts in the middle part of the throat just behind the oral cavity that can be seen when the mouth is open.
<br/>
<h4 style={{fontWeight:"bold"}}>The oral cavity (mouth) and oropharynx (throat)</h4> 
The <span style={{fontWeight:"bold"}}>oral cavity </span> includes the lips, the inside lining of the lips and cheeks (buccal mucosa), the teeth, the gums, the front two-thirds of the tongue, the floor of the mouth below the tongue, the bony roof of the mouth (hard palate) and the area behind the wisdom teeth (called the retromolar trigone).
<br/>
The <span style={{fontWeight:"bold"}}>oropharynx </span> is the middle part of the throat just behind the oral cavity. It can be seen when your mouth is wide open. It includes the base of the tongue (the back third of the tongue), the soft palate (the back part of the roof of the mouth), the tonsils, and the side and back walls of the throat.
<br/>
The oral cavity and oropharynx help you breathe, talk, eat, chew, and swallow. Minor salivary glands all over the oral cavity and oropharynx make saliva (spit) that keeps your mouth and throat moist and helps you digest food.
 
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
        Types of oral cavity (mouth) and oropharynx (throat) cancers

             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             The different parts of the oral cavity and oropharynx are made up of many types of cells. Different cancers can start in each type of cell. These differences are important, because they can determine a person’s treatment options and prognosis (outlook).
             <h4 style={{fontWeight:"bold"}}>Squamous cell carcinoma of the oral cavity and oropharynx</h4>
             Almost all of the cancers in the oral cavity and oropharynx are squamous cell carcinomas, also called squamous cell cancers. These cancers start in squamous cells, which are flat, thin cells that form the lining of the mouth and throat.
             <br/>
             <br/>
             The earliest form of squamous cell cancer is called<span style={{fontWeight:"bold"}}>carcinoma in situ.</span>  This means that the cancer cells are only in the layer of cells called the<span style={{fontWeight:"bold"}}>epithelium</span>  (the top layer of cells lining the oral cavity and oropharynx). This is different from invasive squamous cell cancer, where the cancer cells have grown past the epithelium, into the deeper layers of the oral cavity or oropharynx. 
             <br/>
             <br/>
             HPV-related cancers: Infection with certain high-risk types of the human papillomavirus (HPV) causes most of the squamous cell cancers of the oropharynx (called HPV-positive cancer). HPV is rarely associated with oral cavity cancer. HPV-positive cancers are seen more often in young people with no history of tobacco or alcohol use. These cancers tend to have a better outcome (prognosis) than squamous cell cancers not related to an HPV infection (HPV-negative cancer). This is most likely because HPV-positive cancers shrink when treated with chemotherapy and radiation. See Risk Factors for Oral Cavity and Oropharyngeal Cancers .
             <br/>
             <br/>
             Verrucous carcinoma is a rare type of squamous cell cancer that is most often found in the gums and cheeks. It's a low-grade (slow growing) cancer that hardly ever spreads to other parts of the body.

             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
Other types of oral cavity and oropharynx cancers
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            
             <span style={{fontWeight:"bold"}}>Minor salivary</span> gland cancers: These cancers can start in the glands in the lining of the mouth and throat. There are many types of minor salivary gland cancers, including adenoid cystic carcinoma, mucoepidermoid carcinoma, and polymorphous low-grade adenocarcinoma. To learn more about these cancers, as well as benign salivary gland tumors, see Salivary Gland Cancer.
<br/>
<br/>
<span style={{fontWeight:"bold"}}>Lymphomas:</span>The tonsils and base of the tongue contain immune system (lymphoid) tissue, where cancers called lymphomas can start. For more information about these cancers, see Non-Hodgkin Lymphoma and Non-Hodgkin Lymphoma in Children.
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
                Leukoplakia and erythroplakia (possible pre-cancer conditions)
             </h3>
             <img
               src="image/orel1.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Leukoplakia and erythroplakia are terms used to describe certain types of tissue changes that can be seen in the mouth or throat:
             <br/>
             <br/>
             - Leukoplakia is a white or gray area that does not come off when scraped.<br/>
             <br/>
- Erythroplakia is a flat or slightly raised, red area that often bleeds easily if it's scraped.<br/>
             <br/>
- Erythroleukoplakia is a patch with both red and white areas.<br/>
             <br/>

             Your dentist or dental hygienist may be the first person to find these white or red patches. They might be cancer, they might be a pre-cancer condition called dysplasia, or they could be a harmless change.
             <br/>
             <br/>
             The most common causes of leukoplakia and erythroplakia are smoking and chewing tobacco. Poorly fitting dentures that rub against the tongue or the inside of the cheeks can also cause these changes. But sometimes, there's no clear cause.
             <br/>
             <br/>
             Most cases of leukoplakia do not turn into cancer. But some leukoplakias are either cancer when first found or have pre-cancer changes that can turn into cancer if not properly treated. Erythroplakia and erythroleukoplakia are less common, but are usually more serious. More of these red lesions (compared to white lesions or leukoplakia) turn out to be cancer when they are biopsied or will develop into cancer later.
             <br/>
             <br/>
             Dysplasia is a term that might be used to describe leukoplakia or erythroplakia. Dysplasia can be called mild, moderate, or severe, based on how abnormal the cells look in the lab. Knowing the degree of dysplasia helps predict how likely a lesion is to turn into cancer or go away on its own. For example, severe dysplasia is more likely than mild dysplasia to become cancer. Dysplasia may sometimes go away if the cause (such as poorly fitting dentures) is removed.

             <br/>
             <br/>
             A biopsy is the only way to know for certain if an area of leukoplakia or erythroplakia has dysplastic (pre-cancer) cells or cancer cells. (See Tests for Oral Cavity and Oropharyngeal Cancers.) But other tests might be used first to help determine if a biopsy is needed or to choose the best area to sample for a biopsy. These tests are described in Can Oral Cavity and Oropharyngeal Cancers Be Found Early?
             <br/>
             <br/>
             Still, it's important to note that most oral cancers do not develop from pre-existing lesions (either leukoplakia or erythroplakia).
             </p>
       
           </div>
 
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
                Benign (not cancer) tumors
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Many types of benign tumors and tumor-like changes can start in the mouth or throat, such as these:
             <br></br>
             <br></br>
             - Peripheral giant cell granuloma
             <br></br>
             <br></br>
- Fibroma
<br></br>
             <br></br>
- Granular cell tumor
<br></br>
             <br></br>
- Schwannoma
<br></br>
             <br></br>
- Neurofibroma
<br></br>
             <br></br>
- Pyogenic granuloma
<br></br>
             <br></br>
- Oral hemangioma
<br></br>
             <br></br>
These non-cancer tumors start from different kinds of cells and have many causes. Some of them may cause problems, but they're not likely to be life-threatening. The usual treatment for these types of tumors is surgery to remove them completely since they are unlikely to recur (come back).
             </p>
             
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Bones Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
