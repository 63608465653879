import React from 'react'
import { useEffect } from 'react';


export default function Esophageal() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
  Esophageal Cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/EC.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         What is esophageal cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Esophageal cancer is the 10th most common cancer in the world. It starts in the tissues in your esophagus, the long muscular tube that moves food from your throat to your stomach. Tumors caused by esophageal cancer may not cause noticeable symptoms until the cancer has spread.
<br/>
Healthcare providers treat early-stage esophageal cancer with surgery to remove the tumors or ease symptoms. They may use treatments such as chemotherapy, radiation therapy and immunotherapy to treat more advanced or later-stage esophageal cancer. When they can’t cure the cancer, they focus on helping people live longer, easing symptoms and maintaining quality of life. Medical researchers are working on treatments that will help people with esophageal cancer live longer.
 
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
           Who’s affected by esophageal cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Esophageal cancer affects about 4 in 100,000 people in the U.S. It typically affects men and people designated male at birth (DMAB) who are age 60 or older. It’s more common in men and people DMAB than in women and people designated female at birth (DFAB). People who are Black and people who are Asian with this condition usually have squamous cell esophageal cancer. People who are white are more likely to have adenocarcinoma esophageal cancer.
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         What are esophageal cancer symptoms?
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Difficulty swallowing is the first symptom people may notice. Other symptoms include:
<br/>
- Pain in your throat or back, behind your breastbone or between your shoulder blades.<br/>
- Vomiting or coughing up blood.<br/>
- Heartburn.<br/>
- Hoarseness or chronic cough.<br/>
- Unintentional weight loss.<br/>
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What’s the main cause of esophageal cancer?
             </h3>
             <img
               src="image/EC2.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Healthcare providers don’t know the exact cause, but they’ve identified risk factors that increase the chance of developing esophageal cancer, including:<br/> 
             <br/>   <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Tobacco use:</span>This includes smoking and using smokeless tobacco.
             <br/> <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Alcohol use:</span>Chronic and/or heavy use of alcohol increases the risk of esophageal cancer
             <br/>  <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Obesity:</span>Being overweight or having obesity may cause inflammation in your esophagus that could become cancer.
             <br/> <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Barrett’s esophagus and chronic acid reflux:</span>Barrett’s esophagus is a change in the cells at the lower end of your esophagus that occurs from chronic untreated acid reflux. Even without Barrett’s esophagus, people with long-term heartburn have a higher risk of esophageal cancer.
             <br/>  <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Human papillomavirus (HPV):</span>HPV is a common virus that can cause tissue changes in your vocal cords and mouth and on your hands, feet and genitals.
             <br/>  <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>History of cancer:</span> People who’ve had cancer of the neck or head have a greater risk for esophageal cancer.
             <br/> <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Other disorders:</span>Esophageal cancer is linked to some rare and/or inherited conditions. One is achalasia, an uncommon disease that makes it hard for you to swallow. Another disorder is tylosis, a rare, inherited disorder in which excess skin grows on the palms of your hands and the soles of your feet.
            <br/> <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Occupational exposure to certain chemicals: </span>People exposed to dry cleaning solvents over a long time are at higher risk of developing esophageal cancer.
             </p>
          
           </div>
 
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >How do I reduce my risk of developing esophageal cancer?
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             You may be able to reduce your risk by eating well so you’re at a weight that’s right for you and that you can maintain. You may also reduce your risk by avoiding activities such as using tobacco, frequently drinking lots of alcohol or working around certain solvents. Having an HPV infection is a risk factor for esophageal cancer. Ask your healthcare provider if you should receive the HPV vaccine.
<br/>
There isn’t a recommended way to screen for esophageal cancer. But if you have Barrett’s esophagus or certain other conditions, your healthcare provider may recommend screening. Your healthcare provider may do an EGD to do this screening. If your healthcare provider recommends screening, they’ll let you know how often you should have screening.
             </p>
             
           </div>
           <div>
           <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
              Stages of Esophageal Cancer
             </h3>
             <img src='/image/EG.jpeg' style={{width:"100%"}}></img>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             For squamous cell carcinoma, the stages may be divided based on whether the tumor is in the upper, middle, or lower part of the esophagus, as well as the grade (G) of the tumor cells.
             <br/>



The tumor is in any part of the esophagus and has grown into the third layer of the esophagus. It has also spread to 3 to 6 lymph nodes.
<br/>

The tumor is in any part of the esophagus, has grown into the outer layer of the esophagus and to either 1 to 2 or 3 to 6 lymph nodes.
<br/>

The tumor is in any part of the esophagus and has spread to structures surrounding the esophagus. It has either spread to no lymph nodes or only 1 or 2 lymph nodes.
<br/>

Stage IVA: Meets either of these conditions:
<br/>

The tumor is in any part of the esophagus and has spread to nearby structures. It may also have spread to up to 3 to 6 lymph nodes.
<br/>

The cancer has spread to 7 or more regional lymph nodes.
<br/>

Stage IVB: The cancer has spread to other parts of the body.
<br/>

Staging of adenocarcinoma of the esophagus
For adenocarcinoma, doctors use the T, N, and M classifications, as well as the grade (G).
<br/>

Stage 0: The cancer is found in only the top lining of the esophagus.
<br/>
             </p>
             <img src='/image/EG1.jpeg' style={{width:"100%"}}></img>
             <img src='/image/EG2.jpeg' style={{width:"100%"}}></img>
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Esophageal Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
