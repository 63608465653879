import React from 'react'
import { useEffect } from 'react'


export default function Stomach() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
  stomach cancer
       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/stomach.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
    What Is Stomach Cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Cancer starts when cells in the body begin to grow out of control. Cells in nearly any part of the body can become cancer, and can then spread to other areas of the body. To learn more about cancer and how it starts and spreads, see What Is Cancer?
<br></br>
<br></br>
Stomach cancer, also called gastric cancer, begins when cells in the stomach start to grow out of control.
<br></br>
<br></br>
Stomach cancers tend to develop slowly over many years. Before a true cancer develops, pre-cancerous changes often occur in the inner lining (mucosa) of the stomach. These early changes rarely cause symptoms, so they often go undetected.
<br></br>
<br></br>
Cancers starting in different sections of the stomach can cause different symptoms and tend to have different outcomes. The cancer’s location can also affect treatment options. For example, cancers that start at or grow into the GE junction are usually staged and treated the same as cancers of the esophagus. (For more information, see Esophagus Cancer.)
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
      Types of stomach cancer

             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            <span style={{fontWeight:"bold"}}>Adenocarcinomas</span> 
            <br/>
            <br/>
            Most cancers of the stomach (about 90% to 95%) are adenocarcinomas. These cancers develop from the gland cells in the innermost lining of the stomach (the mucosa).
            <br/>
            <br/>
If you are told you have stomach cancer (or gastric cancer), it will almost always be an adenocarcinoma. The information on the following pages that discusses stomach cancer refers to this type of cancer.
<br/>
            <br/>
There are 2 main types of stomach adenocarcinomas:
<br/>
            <br/>
- The intestinal type tends to have a slightly better prognosis (outlook). The cancer cells are more likely to have certain gene changes that might allow for treatment with targeted drug therapy.
<br></br>
<br></br>
- The diffuse type tends to grow spread more quickly. It is less common than the intestinal type, and it tends to be harder to treat.
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
Other types of cancer that can start in the stomach
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
           <br></br>
           <br></br>
        
        <span style={{fontWeight:"bold"}}> Gastrointestinal stromal tumors (GISTs)</span>  
        <br></br>
           <br></br>
           These uncommon tumors start in very early forms of cells in the wall of the stomach called interstitial cells of Cajal. Some GISTs are much more likely than others to grow into other areas or spread to other parts of the body. Although GISTs can start anywhere in the digestive tract, most start in the stomach. For more information, see Gastrointestinal Stromal Tumor (GIST).
           <br/>
           <br/>
           <span style={{fontWeight:"bold"}}>Neuroendocrine tumors (including carcinoids)</span>
           <br></br>
           <br></br>
           Neuroendocrine tumors (NETs) start in cells in the stomach (or other parts of the digestive tract) that act like nerve cells in some ways and like hormone-making (endocrine) cells in others. Most NETs tend to grow slowly and do not spread to other organs, but some can grow and spread quickly. NETs are discussed in more detail in Gastrointestinal Neuroendocrine (Carcinoid) Tumors.
           <br></br>
           <br></br>  
           <span style={{fontWeight:"bold"}}>Lymphomas</span>
           <br/>
           <br/>
           These cancers start in immune system cells called lymphocytes. Lymphomas usually start in other parts of the body, but some can start in the wall of the stomach. The treatment and outlook for these cancers depend on the type of lymphoma and other factors. For more information, see Non-Hodgkin Lymphoma.
           <br/>
           <br/>
             </p>
 
           </div>
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
                Leukoplakia and erythroplakia (possible pre-cancer conditions)
             </h3>
             <img
               src="image/orel1.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Leukoplakia and erythroplakia are terms used to describe certain types of tissue changes that can be seen in the mouth or throat:
             <br/>
             <br/>
             - Leukoplakia is a white or gray area that does not come off when scraped.<br/>
             <br/>
- Erythroplakia is a flat or slightly raised, red area that often bleeds easily if it's scraped.<br/>
             <br/>
- Erythroleukoplakia is a patch with both red and white areas.<br/>
             <br/>

             Your dentist or dental hygienist may be the first person to find these white or red patches. They might be cancer, they might be a pre-cancer condition called dysplasia, or they could be a harmless change.
             <br/>
             <br/>
             The most common causes of leukoplakia and erythroplakia are smoking and chewing tobacco. Poorly fitting dentures that rub against the tongue or the inside of the cheeks can also cause these changes. But sometimes, there's no clear cause.
             <br/>
             <br/>
             Most cases of leukoplakia do not turn into cancer. But some leukoplakias are either cancer when first found or have pre-cancer changes that can turn into cancer if not properly treated. Erythroplakia and erythroleukoplakia are less common, but are usually more serious. More of these red lesions (compared to white lesions or leukoplakia) turn out to be cancer when they are biopsied or will develop into cancer later.
             <br/>
             <br/>
             Dysplasia is a term that might be used to describe leukoplakia or erythroplakia. Dysplasia can be called mild, moderate, or severe, based on how abnormal the cells look in the lab. Knowing the degree of dysplasia helps predict how likely a lesion is to turn into cancer or go away on its own. For example, severe dysplasia is more likely than mild dysplasia to become cancer. Dysplasia may sometimes go away if the cause (such as poorly fitting dentures) is removed.

             <br/>
             <br/>
             A biopsy is the only way to know for certain if an area of leukoplakia or erythroplakia has dysplastic (pre-cancer) cells or cancer cells. (See Tests for Oral Cavity and Oropharyngeal Cancers.) But other tests might be used first to help determine if a biopsy is needed or to choose the best area to sample for a biopsy. These tests are described in Can Oral Cavity and Oropharyngeal Cancers Be Found Early?
             <br/>
             <br/>
             Still, it's important to note that most oral cancers do not develop from pre-existing lesions (either leukoplakia or erythroplakia).
             </p>
       
           </div> */}
 
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
                Benign (not cancer) tumors
             </h3>
            
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Many types of benign tumors and tumor-like changes can start in the mouth or throat, such as these:
             <br></br>
             <br></br>
             - Peripheral giant cell granuloma
             <br></br>
             <br></br>
- Fibroma
<br></br>
             <br></br>
- Granular cell tumor
<br></br>
             <br></br>
- Schwannoma
<br></br>
             <br></br>
- Neurofibroma
<br></br>
             <br></br>
- Pyogenic granuloma
<br></br>
             <br></br>
- Oral hemangioma
<br></br>
             <br></br>
These non-cancer tumors start from different kinds of cells and have many causes. Some of them may cause problems, but they're not likely to be life-threatening. The usual treatment for these types of tumors is surgery to remove them completely since they are unlikely to recur (come back).
             </p>
             
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Bones Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
