import React from 'react'

const Footer = () => {
  return (
   <>
   <section className="footer">
	<div className="icons-container">

		<div className="icons">
			<i className="fas fa-clock"></i>
			<h3>opening hours</h3>
			<p>09:00am to 05:00pm</p>
		</div>

		<div className="icons">
			<i className="fas fa-phone"></i>
			<h3>phone</h3>
			{/* <p>+91 9587025972</p> */}
			<p>+91 9340592771</p>
			{/* <!-- <p>2345678888</p> --> */}
		</div>

		<div className="icons">
			<i className="fas fa-envelope"></i>
			<h3>email</h3>
			<p>naveen.vermab4@gmail.com</p>
			{/* <p>dfghj69@gamil.com</p> */}
		</div>

		
		<div className="icons">
			<i className="fas fa-map"></i>
			<h3>address(current)</h3>
			<p>Mittal Hospital, Junwani road near Surya Mall Kohka, 490023</p>
		</div>
		
	</div>

    <div className="share">
        <a href="#" className="fab fa-facebook-f"></a>
        <a href="#" className="fab fa-twitter"></a>
        <a href="#" className="fab fa-instagram"></a>
        <a href="#" className="fa fa-mail-bulk"></a>
    </div>

	<div className="credit"> <span> </span>  all rights reserved!</div>

</section>
   </>
  )
}

export default Footer