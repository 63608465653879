import React from 'react'

const Appoinment = () => {
  return (
    <>
       <section className="book" id="book">

<h1 className="heading"> <span>Book your appointment</span> now </h1>


<div className="row">

    <div className="image">
        <img src="image/appointment.jpg" alt="" />
    </div>
    <form action="">
        <h3>Book Appointment</h3>
        <input type="text" placeholder="Your name" className="box" />
        <input type="number" placeholder="Mobile number" className="box" />
        <input type="email" placeholder="Your email" className="box" />
        <input type="text" style={{ height: '10rem', paddingBottom: '10rem' }}
            placeholder="Raise Your Concern" className="box input-placeholder" />

        <input type="date" className="box" />

        <br />

        <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Book now
        </button>
        {/*   
<!-- Modal --> */}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" style={{ fontSize: '13px', height: '48px', fontWeight: 'bold', backgroundColor: '#2cbb71' }}>
                        Your concern has been recorded, our team will contact you shortly.
                    </div>

                </div>
            </div>
        </div>

    </form>
</div>

</section>
    </>
  )
}

export default Appoinment