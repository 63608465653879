import React from 'react'
import About from './About'
import Appoinment from './Appoinment'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()
    return (
        <>
            {/* <!-- home section starts  --> */}
            <section className="home" id="home">

                <div className="image">
                    <img src="image/drnav2.jpeg" alt="" />
                </div>

                <div className="content">
                    <h3>Precision in every incision, excellence in every outcome.</h3>
                    <p> We offer high-quality surgical services with a focus on precision, safety, and patient satisfaction. With a team of skilled and experienced surgeons, 
                        we provide a range of surgical procedures using advanced techniques and state-of-the-art equipment.
                         Our patient-centric approach ensures that every individual receives personalized care and attention throughout their surgical journey.
                         We are committed to delivering exceptional outcomes and helping our patients achieve optimal health and wellbeing.</p>
                    {/* <a  className="btn"> contact us <span className="fas fa-chevron-right"></span> </a> */}
                </div>

            </section>
            {/* <!-- home section ends -->

<!-- icons section starts  --> */}
            {/* <section className="icons-container">

                <div className="icons">
                    <i className="fas fa-user-md"></i>
                    <h3>140+</h3>
                    <p>doctors at work</p>
                </div>

                <div className="icons">
                    <i className="fas fa-users"></i>
                    <h3>1040+</h3>
                    <p>satisfied patients</p>
                </div>

                <div className="icons">
                    <i className="fas fa-procedures"></i>
                    <h3>500+</h3>
                    <p>bed facility</p>
                </div>
            </section> */}
            {/* <!-- icons section ends -->

<!-- services section starts  --> */}
<div className="headline text-center">
            <h1 className="heading" style={{paddingTop: "50px"}}> Why <span> Choose us</span> </h1>
            </div>
            <br></br>
        
       <section className="icons-container">
           

                <div className="icons">
                    <i className="fas fa-user-md"></i>
                    <h3>Quick Response</h3>
                    <p>our team of professionals is trained to respond quickly and effectively to mitigate any potential risks and ensure the safety of our clients. With a focus on rapid response times and a commitment to delivering the highest level of service.</p>
                </div>

                <div className="icons">
                    <i className="fas fa-users"></i>
                    <h3>Satisfied Patients</h3>
                    <p>Experience exceptional care, leave with a smile - Our satisfied patients say it all!</p>
                </div>

                <div className="icons">
                    <i className="fas fa-procedures"></i>
                    <h3>Quality service</h3>
                    <p>With a focus on convenience and customer satisfaction,
                       we aim to offer a hassle-free and strive to exceed expectations and deliver the best possible outcomes. A time-saving experience for all our clients.</p>
                </div>
            </section>


            <section className="services" id="services">

                <h1 className="heading"> Our <span>Services</span> </h1>

                <div className="box-container">

                    {/* <!-- <a href="IVF.html" className="btn" > --> */}
                    <button><a onClick={()=>navigate('/Itfemails')} style={{ color: 'black' }}> <div className="box" style={{ backgroundColor: '#ffc107' }}>
                        <i className="fas fa-notes-medical"></i>
                        {/* <!-- <span className="box" style="background-color: black !important">In Vitro Fertilization(IVF)</span> --> */}
                        <h3 style={{ color: 'black' }}> Uterine Cancer</h3>
                        {/* <!-- <p style="color: white;">Lorem ipsum dolor sit amet consectetur,</p> --> */}
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>

                    </div></a></button>


                    <button><a  onClick={()=>navigate('/orell')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#409fe3' }}>
                        <i className="fas fa-ambulance"></i>
                        <h3 style={{ color: 'black' ,}}> Oral Cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>
                    </div></a></button>

                    <button><a  onClick={()=>navigate('/stomach')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#f86c5e ' }} >
                        <i className="fas fa-user-md"></i>
                        <h3 style={{ color: 'black' }}> Stomach cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>
                    </div></a></button>

                    <button><a  onClick={()=>navigate('/colorectal')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#12cdca' }} >
                        <i className="fas fa-pills"></i>
                        <h3 style={{ color: 'black' }}> Colorectal cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>
                    </div></a></button>

                    <button><a onClick={()=>navigate('/pancreatic')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#2cbb71' }} >
                        <i className="fas fa-procedures"></i>
                        <h3 style={{ color: 'black' }}>Pancreatic Cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>

                    </div></a></button>



                    <button><a  onClick={()=>navigate('/esophageal')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#409fe3' }} >
                        <i className="fas fa-ambulance"></i>
                        <h3 style={{ color: 'black' }}>Esophageal Cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>
                    </div></a></button>

                    <button><a  onClick={()=>navigate('/bladder')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#f86c5e ' }}>
                        <i className="fas fa-ambulance"></i>
                        <h3 style={{ color: 'black' }}>Bladder Cancer</h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>
                    </div></a></button>


                    <button><a  onClick={()=>navigate('/bones')} style={{ color: 'black' }}><div className="box" style={{ backgroundColor: '#ffc107' }} >
                        <i className="fas fa-procedures"></i>
                        <h3 style={{ color: 'black' }}>Bone Cancer </h3>
                        <h4 style={{ paddingLeft: "16rem", paddingTop: "3rem" }}> Read more</h4>

                    </div></a></button>

                </div>

            </section>
            {/* <!-- about section starts  --> */}
          <About />
            {/* <!-- doctors section starts  --> */}

            <section className="doctors" id="doctors">

                <h1 className="heading"> Meet the <span>doctors</span> </h1>

                <div className="box-container">
                <div className="box">
                        <img src="image/drnav2.jpeg" alt="" />
                        <h3>Dr. Naveen Verma</h3>
                        <span>MBBS, MS, MCH surgical oncology</span>
                        <div className="share">
                            <a href="#" className="fab fa-facebook-f"></a>
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-instagram"></a>
                            <a href="#" className="fa fa-mail-bulk"></a>
                        </div>
                    </div>
                

                    <a href='https://www.drnamitachandra.com/' target='_blank'>
                    <div className="box">
                        
                        <img src="image/port1.jpeg" alt="" />
                        
                        <h3>Dr. Namita Chandra Verma</h3>
                        
                        <span>MBBS, DNB, DRM, fellowship (infertility and IVF )</span>
                        <div className="share">
                            <a href="#" className="fab fa-facebook-f"></a>
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-instagram"></a>
                            <a href="#" className="fa fa-mail-bulk"></a>
                        </div>
                    </div>
                    </a>

                  



                </div>

            </section>


            {/* // */}
         <Appoinment />



        </>
    )
}

export default Home