import React from 'react'

const Gynecology = () => {
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>    <h1 style={{ 
      textAlign: "center", 
      fontWeight: "bold",
      paddingBottom: "2rem",
      textShadow: "var(--text-shadow)",
      textTransform: "uppercase",
      color: "var(--black)",
      fontSize: "5rem",
      letterSpacing: ".4rem"
    }}>
      we Always Provide Best
      <span style={{ 
        textTransform: "uppercase",
        color: "var(--green)"
      }}>
        Services
      </span>
    </h1>
    ;<div className="container-fluid">
  <div className="row">
    <div className="col-md-9">
      <div>
        <img
          src="image/gyn.jpg"
          className="img-fluid"
          style={{
            width: "84rem",
            height: "30rem",
            marginBottom: 20,
            marginTop: 20,
          }}
        />
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
          }}
        >
          Gynaecological Surgery
        </h3>
        <p style={{ color: "black", fontSize: 15 }}>
          Our expert panels of surgeons, with extensive years of experience in
          gynaecological &amp; laparoscopic surgeries, provide the best course
          of treatment available today. Our
          <br /> surgical experts prioritize treatment based on sheer merit as a
          matter of principal and <br />
          advise surgical options only as a last resort, when indicated and
          necessary.
        </p>
      </div>
      <div>
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
          }}
        >
          Diagnostic And Operative Laparoscopy And Hysteroscopy
        </h3>
        <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
          It is a diagnostic process, which details the exact condition of the
          uterus, tubes and surrounding structures. The procedure involves a
          telescope, which is inserted into the abdomen through the belly button
          by way of a very small cut. It is further inserted into the uterus
          through the neck of the womb, called the cervix. The procedure is
          performed under anesthesia.
          <br />
          <br />
          An ideal method of diagnosis over other investigative tools (like
          ultrasound, hysterosalpingogram) as it provides a clearer view. These
          endoscopic techniques help in identifying and correcting any
          underlying disorders that can hamper the possibility of a healthy
          pregnancy, such as ovarian cyst, endometriosis, adhesions (scar
          tissue) in the abdomen affecting tubal function, uterine fibroid,
          polyp or scar tissue.
        </p>
      </div>
      <div>
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
          }}
        >
          Success Rate of Gynaecology:
        </h3>
        <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
          In best cases (good sperm, egg and uterine quality) the success rate
          may reach up to 50-60% <br />
          attempt and 90% in 2-3 attempts. However, where quality is not good
          success rates are lower.
        </p>
      </div>
      <div>
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
          }}
        >
          Process Of Gynaecology :
        </h3>
        <img
          src="image/processgym.jpg"
          className="img-fluid"
          style={{
            marginBottom: 20,
            width: "84rem",
            height: "30rem",
            marginTop: 20,
          }}
        />
        <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
          The Gynaecology process consists of{" "}
          <span style={{ color: "brown", fontWeight: "bold" }}>
            Cervical Cryosurgery, Colposcopy, Dilation and Curettage (D&amp;C),
            Hysteroscopy and Pelvic Laparoscopy.
          </span>{" "}
          Screening processes like normal ultrasound and blood tests are a
          pre-requisite for proceeding with Gynaecology. If any abnormality is
          detected, it is treated before proceeding with Gynaecological Process.
        </p>
        <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
          Abnormal cervical cells are sometimes discovered during a routine Pap
          smear (screening for cervical cancer). Abnormal cells do not always
          indicate cancer, but your healthcare provider will likely want to do
          some follow-up tests or procedures.
          <br />
          <br />
          Abnormal cervical cells that show changes indicating it could lead to
          cancer are called precancerous cells. Cryosurgery destroys these cells
          to stop them from developing into cancer.
          <br />
          A colposcopy is a non-surgical diagnostic tool used to examine the
          cervix, vagina, and vulva more closely. It is sometimes used when a
          person has an abnormal Pap smear. During the procedure, a healthcare
          provider uses a colposcope, a magnifying instrument.
          <br />
          <br />
          If an area appears concerning during this procedure, they may take a
          sample and send it to the laboratory for testing.Dilation and
          curettage (D&amp;C) is one of the most common gynecological
          procedures. During this procedure, a healthcare provider removes a
          portion of your uterine lining with a suction device or a sharp
          curette (a surgical instrument used for scraping). Hysteroscopy is a
          procedure that your healthcare provider may use to diagnose or treat
          uterine problems. During this procedure, a healthcare professional
          inserts a hysteroscope, a thin, lighted, telescope-like instrument,
          into your uterus through the vagina. Then, it sends pictures of your
          uterus to a screen for further examination.
          <br />
          <br />
          When a PAP smear indicates abnormal cells on the surface of the
          cervix, a healthcare provider may recommend a loop electrosurgical
          excision procedure (LEEP). An electrically-charged, thin wire loop is
          used to cut away the abnormal tissue during the procedure. The
          procedure is fast and performed under local anesthesia in a doctor's
          office.
          <br />
          <br />
          <span style={{ color: "brown", fontWeight: "bold" }}>
            Common gynecological procedures include cervical cryosurgery,
            colposcopy, D&amp;C, hysteroscopy, LEEP, and pelvic laparoscopy.
            Many of these procedures can help healthcare providers get a better
            look at the uterus, cervix, and vagina and take samples for a
            biopsy. Some of them can also treat certain conditions.
          </span>
        </p>
      </div>
      {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      Estimated Time Required And When To Arrive:
                  </h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;">
                      One IVF cycle takes about l5 days from start of period to embryo transfer. In this case, you
                      need<br> to reach 3-4 days before the period starts.<br>
                      Many patients may not be able to spare so much time. If you collect your medications from us
                      in<br> advance your stay can be reduced to a week to 10 days around the time of embryo
                      transfer. In <br>this case, you can reach on the 7th or 8th day of your period.<br>
                      You can travel back 2-3 days after embryo transfer. We are sure that travelling does not
                      reduce <br>the chance of pregnancy.<br>
                      It takes 15 days from the embryo transfer to obtain the pregnancy test result.
                  </p>
              </div> */}
      <div>
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
          }}
        >
          Number Of Visits And Hospitalization:
        </h3>
        <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
          The total number of visits may vary from 4-6 usually.
          <br />
          There is no overnight hospitalization and both egg pick up andembryo
          transfer are day care
          <br />
          procedures.
          <br />
          Husband needs to come on day of egg pick up or freeze sperms
          beforehand.
        </p>
      </div>
      {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies
                      from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs.
                      The package includes the hormonal injection used for stimulation, the oral medication and
                      vitamins for husband and wife during IVF stimulation, doctors consultation fees and
                      follicular monitoring during the IVF cycle.</p>
              </div>
              <a href="services.html" target="_blank" class="btn" style="margin-bottom: 20px;"> Test Tube Baby/
                  IVF Calculator <span class="fas fa-chevron-right"></span> </a> */}
      <h3
        style={{
          color: "brown",
          fontWeight: "bolder",
          fontSize: 20,
          marginBottom: 20,
        }}
      >
        For further queries related to the treatment, Please call +91-9587025972
        or write to namita_chandra@yahoo.co.in
      </h3>
    </div>
  </div>
</div>


    </section>
    ;<div>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h3
          style={{
            color: "black",
            fontWeight: "bolder",
            fontSize: 30,
            textShadow: "var(--text-shadow)",
            marginBottom: 20,
            textAlign: "center",
            marginTop: 19,
          }}
        >
          Our Related Services
        </h3>
        <div className="swiper food-slider">
          <div className="swiper-wrapper" style={{ marginBottom: 30 }}>
            <div
              className="swiper-slide slide"
              data-name="food-1"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/A Treatment That Is Helping Couples Become Parents.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                A Treatment That Is Helping Couples Become Parents
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-2"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/FERC.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Frozen Embryo Replacement Cycle (FERC)
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-3"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/Surgical Sperm Retrieval.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Surgical Sperm Retrieval
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-4"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ivf pre.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                IVF Pregnancy
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/faild ivf.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Failed IVF Cycle
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ICSI.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Intracytoplasmic Sperm Injection (ICSI)
              </h3>
            </div>
            <div
              className="swiper-slide slide"
              data-name="food-5"
              style={{
                marginLeft: 20,
                height: "32rem",
                width: "413.667px",
                background: "var(--white)",
                boxShadow: "var(--box-shadow)",
                border: "var(--border)",
                marginRight: 20,
              }}
            >
              <div id="holder">
                <img
                  src="image/ivf-c.png"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
              </div>
              <h3
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                In Vitro Fertilization (IVF)
              </h3>
            </div>
            <div id="holder">
              <div
                className="swiper-slide slide"
                data-name="food-5"
                style={{
                  marginLeft: 20,
                  height: "32rem",
                  width: "413.667px",
                  background: "var(--white)",
                  boxShadow: "var(--box-shadow)",
                  border: "var(--border)",
                  marginRight: 20,
                }}
              >
                <img
                  src="image/iui.jpeg"
                  style={{ width: 417, height: "25rem", marginBottom: 10 }}
                />
                <h3
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  Intrauterine Insemination (IUI)
                </h3>
              </div>
            </div>
          </div>
          <div className="swiper-pagination " />
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
</div>

    </>
  )
}

export default Gynecology