import React from 'react'
import { useEffect } from 'react'


const Itfemails = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
   <>
   <section style={{ backgroundColor: "#f5f5f5" }}>
    <h1
      style={{
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom: "2rem",
        textShadow: "var(--text-shadow)",
        textTransform: "uppercase",
        color: "var(--black)",
        fontSize: "5rem",
        letterSpacing: ".4rem",
      }}
    >
    Uterine Cancer
      {/* <span style="    text-transform: uppercase;
      color: var(--green);"> Services</span> */}
    </h1>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div>
            <img
              src="image/uterine-cancer.jpg"
              className="img-fluid"
              style={{
                width: "100%",
                height: "500px",
                marginBottom: 20,
                marginTop: 20,
              }}
            />
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
          What are the symptoms of uterine cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15 }}>
            Signs of uterine cancer can resemble those of many conditions. That’s especially true of other conditions affecting reproductive organs. If you notice unusual pain or irregular vaginal bleeding, talk to your healthcare provider. An accurate diagnosis is important so you can get the proper treatment.

Symptoms of endometrial cancer or uterine sarcoma include:

Vaginal bleeding between periods before menopause.
Vaginal bleeding or spotting after menopause, even a slight amount.
Lower abdominal pain or cramping in your pelvis, just below your belly.
Thin white or clear vaginal discharge if you’re postmenopausal.
Extremely prolonged, heavy or frequent vaginal bleeding if you’re older than 40.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
            What causes uterine cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Researchers aren’t sure of the exact cause of uterine cancer. Something happens to create changes in cells in your uterus. The mutated cells grow and multiply out of control, which can form a mass called a tumor.

Certain risk factors can increase the chances you’ll develop uterine cancer. If you’re at high risk, talk to your healthcare provider about steps you can take to protect your health.
            </p>
          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
             What are risk factors for uterine cancer?
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            There are several risk factors for endometrial cancer. Many of them relate to the balance between estrogen and progesterone. These risk factors include having obesity, a condition called polycystic ovarian syndrome (PCOS) or taking unopposed estrogen (taking estrogen without taking progesterone, too). The genetic disorder Lynch syndrome is another risk factor unrelated to hormones.

Risk factors.
            </p>

          </div>
          <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
             Prevention
            </h3>
            <img
              src="image/UC.jpg"
              className="img-fluid"
              style={{
                marginBottom: 20,
                width: "100%",
                height: "300px",
                marginTop: 20,
              }}
            />
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            To reduce your risk of endometrial cancer, you may wish to:

Talk to your doctor about the risks of hormone therapy after menopause. If you're considering hormone replacement therapy to help control menopause symptoms, talk to your doctor about the risks and benefits. Unless you've undergone a hysterectomy, replacing estrogen alone after menopause may increase your risk of endometrial cancer. Taking a combination of estrogen and progestin can reduce this risk. Hormone therapy carries other risks, so weigh the benefits and risks with your doctor.
Consider taking birth control pills. Using oral contraceptives for at least one year may reduce endometrial cancer risk. The risk reduction is thought to last for several years after you stop taking oral contraceptives. Oral contraceptives have side effects, though, so discuss the benefits and risks with your doctor.
Maintain a healthy weight. Obesity increases the risk of endometrial cancer, so work to achieve and maintain a healthy weight. If you need to lose weight, increase your physical activity and reduce the number of calories you eat each day.
            </p>
            {/* <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Ovulation induction is usually used in the sense of stimulation of
              the development of ovarian follicles by fertility medication to
              reverse anovulation or oligoovulation. These medications are given
              by injection for 8 to 14 days. A health care provider closely
              monitors the development of the eggs using transvaginal ultrasound
              and blood tests to assess follicle growth and estrogen production
              by the ovaries. When follicles have reached an adequate size and
              the eggs are mature enough, an injection of the hormone hCG
              initiates the ovulation process. Egg retrieval should occur from
              34 to 36 hours after the hCG injection
            </p> */}
          </div>

          {/* Time period */}
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Estimated Time Required:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              Most couples are able to get pregnant within six months to a year.
              If you're unable to conceive after a full year of trying, it's a
              good idea to consult a fertility specialist. Sometimes there's an
              obvious cause of infertility, like a physical problem with the
              ovaries, uterus, or testicles.
            </p>
          </div> */}
          {/* <div>
            <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >
              Number Of Visits And Hospitalization:
            </h3>
            <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
              If you have been trying to conceive for more than a year (or six
              months if you are over 35 years old), it is recommend that you
              undergo an evaluation. A fertility specialist can help determine
              why you are having difficulty and offer possible treatment options
              to help you conceive.
            </p>
          </div> */}
          {/* <div>
                  <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
              </div> */}
          {/* <a
            href="services.html"
            target="_blank"
            className="btn"
            style={{ marginBottom: 20 }}
          >
            {" "}
            Test Tube Baby/ IVF Calculator{" "}
            <span className="fas fa-chevron-right" />{" "}
          </a> */}
          <h3
            style={{
              color: "brown",
              fontWeight: "bolder",
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            For further queries related to the treatment, Please call
            +91-9587025972 or write to naveen.vermab4@gmail.com
          </h3>
        </div>
      </div>
    </div>
  </section>
  {/* booking section ends */}
  {/* Remove the container if you want to extend the Footer to full width. */}
  <div className="container my-5"></div>


   </>
  )
}

export default Itfemails