import React from 'react'
import { useEffect } from 'react'


export default function Pancreatic() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
    <section style={{ backgroundColor: "#f5f5f5" }}>
     <h1
       style={{
         textAlign: "center",
         fontWeight: "bold",
         paddingBottom: "2rem",
         textShadow: "var(--text-shadow)",
         textTransform: "uppercase",
         color: "var(--black)",
         fontSize: "5rem",
         letterSpacing: ".4rem",
       }}
     >
Pancreatic Cancer       {/* <span style="    text-transform: uppercase;
       color: var(--green);"> Services</span> */}
     </h1>
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-12">
           <div>
             <img
               src="image/PC.jpeg"
               className="img-fluid"
               style={{
                 width: "100%",
                 height: "500px",
                 marginBottom: 20,
                 marginTop: 20,
               }}
             />
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
         What is pancreatic cancer?
             </h3>
             <p style={{ color: "black", fontSize: 15 }}>
             Pancreatic cancer occurs when cells in your pancreas mutate (change) and multiply out of control, forming a tumor. Your pancreas is a gland in your abdomen (belly), between your spine and stomach. It makes hormones that control blood-sugar levels and enzymes that aid in digestion.
<br/>
Most pancreatic cancers start in the ducts of your pancreas. The main pancreatic duct (the duct of Wirsung) connects your pancreas to your common bile duct.
<br/>

Early-stage pancreatic tumors don’t show up on imaging tests. For this reason, many people don’t receive a diagnosis until the cancer has spread (metastasis). Pancreatic cancer is also resistant to many common cancer drugs, making it notoriously difficult to treat.
<br/>

Ongoing research focuses on early detection through genetic testing and new imaging methods. Still, there’s much to learn.
 
 
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
       Types of pancreatic cancer
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             There are two main types of pancreatic tumors:<br/>
             <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Exocrine tumors:</span>  Over 90% of all pancreatic tumors are exocrine tumors. The most common type of pancreatic cancer is adenocarcinoma, which begins in the cells that line your organs.
           <br/>  <span className='me-2' style={{fontSize:"20px",fontWeight:"bold"}}>Neuroendocrine tumors:</span>  Less than 10% of pancreatic tumors are neuroendocrine tumors (NETs). Islet cell carcinoma is another name for an NET.
             </p>
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
           What are the symptoms of pancreatic cancer?

             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             Unfortunately, there aren’t any early signs of pancreatic cancer. Symptoms typically emerge once the tumor starts impacting other organs in your digestive system.<br/>
             Pancreatic cancer symptoms may include:<br/>
             - Jaundice (yellowing of your skin).<br/>
             - Dark urine (pee).<br/>
- Light-colored stool (poop).<br/>
- Upper abdominal pain.<br/>
- Middle back pain.<br/>
- Fatigue.<br/>
- Itchy skin.<br/>
- Nausea and vomiting.<br/>
- Gas or bloating.<br/>
- Lack of appetite.<br/>
- Blood clots.<br/>
- Weight loss.<br/>
- New-onset diabetes.<br/>
Your healthcare provider might suspect pancreatic cancer if you’ve recently developed diabetes or pancreatitis — a painful condition due to inflammation in your pancreas.<br/>
Symptoms of pancreatic neuroendocrine cancer may be different from traditional pancreatic cancer symptoms, such as jaundice or weight loss. Symptoms can vary, but may include diarrhea and anemia.
             </p>
 
           </div>
           <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >What causes pancreatic cancer?
             </h3>
             <img
               src="image/PC2.jpeg"
               className="img-fluid"
               style={{
                 marginBottom: 20,
                 width: "100%",
                 height: "300px",
                 marginTop: 20,
               }}
             />
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
             There isn’t a clear answer. We don’t know exactly what causes pancreatic cancer. But experts have identified some risk factors.<br>

             </br>
             - Smoking cigarettes, cigars and using other forms of tobacco.<br/>
- Obesity, particularly if you carry extra weight around your waist.<br/>
- Diabetes, especially Type 2 diabetes. Sudden-onset diabetes could be a sign of pancreatic cancer.<br/>
- Exposure to certain chemicals, like pesticides and petrochemicals.<br/>
- Chronic pancreatitis, a permanent inflammation of your pancreas.<br/>
- Hereditary chronic pancreatitis due to gene changes (mutations) passed from biological parent to child.<br/>
- Hereditary syndromes with changes (mutations) in genes, such as BRCA1 or BRCA2 genes passed from biological parent to child.<br/>
             </p>
          
           </div>
           <div>
           <h3
              style={{
                color: "black",
                fontWeight: "bolder",
                fontSize: 30,
                textShadow: "var(--text-shadow)",
                marginBottom: 20,
              }}
            >What are the Pancreatic cancer stages?
            </h3>
            <img src="/image/PC3.jpeg" style={{width:"100%"}}></img>
           </div>
 
        
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Estimated Time Required:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               Most couples are able to get pregnant within six months to a year.
               If you're unable to conceive after a full year of trying, it's a
               good idea to consult a fertility specialist. Sometimes there's an
               obvious cause of infertility, like a physical problem with the
               ovaries, uterus, or testicles.
             </p>
           </div> */}
           {/* <div>
             <h3
               style={{
                 color: "black",
                 fontWeight: "bolder",
                 fontSize: 30,
                 textShadow: "var(--text-shadow)",
                 marginBottom: 20,
               }}
             >
               Number Of Visits And Hospitalization:
             </h3>
             <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
               If you have been trying to conceive for more than a year (or six
               months if you are over 35 years old), it is recommend that you
               undergo an evaluation. A fertility specialist can help determine
               why you are having difficulty and offer possible treatment options
               to help you conceive.
             </p>
           </div> */}
           {/* <div>
                   <h3 style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">COST OF IVF</h3>
                   <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs. The package includes the hormonal injection used for stimulation, the oral medication and vitamins for husband and wife during IVF stimulation, doctors consultation fees and follicular monitoring during the IVF cycle.</p>
               </div> */}
           {/* <a
             href="services.html"
             target="_blank"
             className="btn"
             style={{ marginBottom: 20 }}
           >
             {" "}
             Pancreatic Cancer{" "}
             <span className="fas fa-chevron-right" />{" "}
           </a> */}
           <h3
             style={{
               color: "brown",
               fontWeight: "bolder",
               fontSize: 20,
               marginBottom: 20,
             }}
           >
             For further queries related to the treatment, Please call
             +91-9587025972 or write to naveen.vermab4@gmail.com
           </h3>
         </div>
       </div>
     </div>
   </section>
   {/* booking section ends */}
   {/* Remove the container if you want to extend the Footer to full width. */}
   <div className="container my-5"></div>
 
 
    </>
  )
}
