import React from 'react';

const  Gallery=()=>{
  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div style={{ marginTop: "20%", marginLeft: "18%" }}>
            <h2 style={{ fontSize: "3rem", color: "#0088a5", fontFamily: "Poppins, sans-serif!important" }} className="mb-3">Doctor</h2>
            <h1 style={{ color: "#0088a5", fontSize: "2.7rem", lineHeight: "110%", fontWeight: 500 }} className="">Dr. Namita Chandra Verma</h1>
            <p style={{ fontSize: "21px" }} className="">Gynecologist &amp; Obstetrician &amp; Infertility specialist</p>
            <br />
            <div className="d-flex">
              <i className="fas fa-stethoscope icon text dvc-pink" style={{ color: "#ec268f", fontSize: "25px" }}></i>
              <div className="content ms-3" style={{ fontSize: "22px" }}>MBBS, DNB, Fellowship (Infertility &amp; IVF)</div>
            </div>

            <div className="d-flex">
              <i className="fas fa-stethoscope icon text dvc-pink" style={{ color: "#ec268f", fontSize: "25px" }}></i>
              <div className="content ms-3" style={{ fontSize: "22px" }}>Diploma In Reproductive Medicine(Germany)</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            {/* <img src="image/docattire4.jpeg" className="img-fluid first-img" /> */}
            <video height='400' width="August" controls>
              <source src="/image/videodoc1.MP4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div>
            {/* <img src="image/docattire4.jpeg" className="img-fluid first-img"/> */}
            <video height='400' width="610" controls >
              <source src="/image/videodoc2.MP4" type="video/mp4"/>
            </video>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{marginTop: '20%', marginLeft: '18%'}}>
            {/* <h2 style={{fontSize: '3rem', color: '#0088a5', fontFamily: 'Poppins,sans-serif!important'}} className="mb-3">Doctor</h2> */}
            <h1 className="" style={{color: '#0088a5', fontSize: '2.7rem', lineHeight: '110%', fontWeight: 500}}>Age factors affecting pregnancy</h1>
            <p className="" style={{fontSize: '21px'}}>Gynecologist &amp; Obstetrician &amp; Infertility specialist</p>
            <br />
            <div className="d-flex">
              {/* <i className="fas fa-stethoscope icon text dvc-pink" style={{color: '#ec268f', fontSize: '25px'}}></i> */}
              <div className="content ms-3" style={{fontSize: '22px'}}>Age count exceeding 30 will result in few complications</div>
            </div>
            <div className="d-flex"> 
              {/* <i className="fas fa-stethoscope icon text dvc-pink" style={{color: '#ec268f', fontSize: '25px'}}></i> */}
              <div className="content ms-3" style={{fontSize: '22px'}}>Diploma In Reproductive Medicine(Germany)</div>
            </div>          
          </div>
        </div>
      </div>
    </div>
    </>
    
  );
}

export default Gallery;
