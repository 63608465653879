import React from 'react'

const Donor = () => {
  return (
   <>
   ;<section style={{ backgroundColor: "#f5f5f5" }}>
  <h1
    style={{
      textAlign: "center",
      fontWeight: "bold",
      paddingBottom: "2rem",
      textShadow: "var(--text-shadow)",
      textTransform: "uppercase",
      color: "var(--black)",
      fontSize: "5rem",
      letterSpacing: ".4rem",
    }}
  >
    Donor Gametes
    {/* <span style="    text-transform: uppercase;
  color: var(--green);"> Services</span> */}
  </h1>
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-9">
        <div>
          <img
            src="image/donor-gemetes.png"
            className="img-fluid"
            style={{
              width: "84rem",
              height: "30rem",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Donor Gametes
          </h3>
          <p style={{ color: "black", fontSize: 15 }}>
            Gamete donation is giving sperm, eggs, both, or embryos to another
            person/couple so that another person/couple may have a child. This
            offers patients the opportunity to have child(ren) when sperm and/or
            eggs or both are not healthy enough to lead to successful pregnancy.
            When only sperm or egg is donated, it allows patients to have
            children that will have a genetic link to one of the partners. It
            can also permit the patients to experience the pregnancy and
            birthing event.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            What to expect from the term "Donor Gametes"?
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Before IUI, you may take fertility medicines that help make your
            eggs mature and ready to be fertilized. One of the most difficult
            challenges parents face is whether or not to tell their children
            about the donation. Conventional wisdom about whether to tell
            children they were adopted has changed over recent years. Early on,
            intended parents were advised never to disclose the child’s
            reproductive origins. Over time this philosophical perspective has
            drastically shifted from a never-tell stance to a must-tell one.
            <br />
            <br />
            It is no longer considered harmful to a child’s well-being to know
            about their genetic makeup. In fact, most mental health
            professionals agree that it is better for the child(ren) when
            parents are open about his/her/their particular family-building
            story.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Success Rate of IVF through Donor Eggs:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            IVF with donor eggs shows a higher success rate in treating
            infertility with a probability of 50%-70% in conceiving in the first
            attempt and 90% with three attempts. This means that on average
            every 4 out of 5 women will find their dream of a child to be met.
          </p>
        </div>
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Process Of Egg Donation/Donor Gamete :
          </h3>
          <img
            src="image/donor-gemetes1.jpg"
            className="img-fluid"
            style={{
              marginBottom: 20,
              width: "84rem",
              height: "30rem",
              marginTop: 20,
            }}
          />
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            The procedure typically involves a doctor removing an egg or eggs
            from the donor, fertilizing them in a laboratory, and then
            transferring the resulting embryos into the recipient’s uterus.
            Doctors do this using an implantation procedure, such as in vitro
            fertilization (IVF).
          </p>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Egg donation frequently benefits women who cannot use their own eggs
            for various reasons, including ovarian failure, avoiding congenital
            anomalies in the fetus, or advanced age. Sometimes, specialists at
            the facility may freeze some or all of the embryos for later use or
            implantation in different women. Specialists at the fertility
            facility will conduct an intensive selection process to find a
            suitable donor and will carefully run through the legal procedures.
            <br />
            <br />
            Some women may experience mild side effects, such as bruising at the
            injection site, mood swings, and tender breasts. In rare cases, a
            woman may develop severe ovarian hyperstimulation syndrome (OHSS).
            This occurs when too many eggs develop in the ovaries. Women who
            develop OHSS may require hospitalization.
            <br />
            <br />
            <span style={{ color: "brown", fontWeight: "bold" }}>
              There is a small risk of infection following the procedure. Your
              doctor will use sterile instruments, so infection is very rare. If
              medications are used to induce ovulation, there is a risk of
              pregnancy with multiple babies.
            </span>
          </p>
        </div>
        {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      Estimated Time Required And When To Arrive:
                  </h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;">
                      One IVF cycle takes about l5 days from start of period to embryo transfer. In this case, you
                      need<br> to reach 3-4 days before the period starts.<br>
                      Many patients may not be able to spare so much time. If you collect your medications from us
                      in<br> advance your stay can be reduced to a week to 10 days around the time of embryo
                      transfer. In <br>this case, you can reach on the 7th or 8th day of your period.<br>
                      You can travel back 2-3 days after embryo transfer. We are sure that travelling does not
                      reduce <br>the chance of pregnancy.<br>
                      It takes 15 days from the embryo transfer to obtain the pregnancy test result.
                  </p>
              </div> */}
        <div>
          <h3
            style={{
              color: "black",
              fontWeight: "bolder",
              fontSize: 30,
              textShadow: "var(--text-shadow)",
              marginBottom: 20,
            }}
          >
            Number Of Visits And Hospitalization:
          </h3>
          <p style={{ color: "black", fontSize: 15, marginBottom: 20 }}>
            Steps for Donor Egg Recipient Process. A cycle takes 3 - 6 months to
            complete from initial screening to the actual retrieval and embryo
            transfer, depending on the type of donor oocyte program you choose.
          </p>
        </div>
        {/* <div>
                  <h3
                      style="color: black; font-weight: bolder; font-size: 30px; text-shadow: var(--text-shadow); margin-bottom: 20px;">
                      COST OF IVF</h3>
                  <p style="color: black; font-size: 15px; margin-bottom: 20px;;">The cost of an IVF cycle varies
                      from Rs 1.30 lac to 1.60 lac, depending on the case. On an average, it is about Rs 1.5 lacs.
                      The package includes the hormonal injection used for stimulation, the oral medication and
                      vitamins for husband and wife during IVF stimulation, doctors consultation fees and
                      follicular monitoring during the IVF cycle.</p>
              </div>
              <a href="services.html" target="_blank" class="btn" style="margin-bottom: 20px;"> Test Tube Baby/
                  IVF Calculator <span class="fas fa-chevron-right"></span> </a> */}
        <h3
          style={{
            color: "brown",
            fontWeight: "bolder",
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          For further queries related to the treatment, Please call
          +91-7987219296 or write to namita_chandra@yahoo.co.in
        </h3>
      </div>
    </div>
  </div>
</section>

   </>
  )
}

export default Donor